.owl-nav > .owl-next-directory,
.owl-nav > .owl-prev-directory {
  border: 1px solid #3673fc !important;
  border-radius: 50% !important;
  color: #3673fc !important;
  font-weight: bold !important;
  font-size: 26px !important;
  width: 40px;
  height: 40px;
  margin: 10px 5px 0;
  line-height: 30px !important;
}

.owl-item .item,
.owl-item.active .item,
.owl-item.active .item,
.owl-item.cloned.active .item,
.owl-item.cloned.active .item,
.owl-item.active.cloned .item {
  opacity: 1;
}

.directory-stage .owl-item {
  /* width: 270.5px !important;
  margin-right: 15px !important;*/
}

.directory-people-slider .shadow-effect {
  height: 400px;
}

.directory-people-slider h5,
.main-directory-box h5 {
  color: #313638;
  font-size: 20px !important;
  margin: 0px 0 8px !important;
  min-height: 55px;
}
.directory-people-slider h6 {
  font-size: 22px !important;
  margin: 14px 0 8px !important;
}
.directory-people-slider p,
.main-directory-box p {
  font-size: 14px !important;
  min-height: 50px;
}
.user-image-box {
  border-radius: 0% !important;
  overflow: hidden !important;
  margin: 0 auto !important;
}
.directory-people-slider h3,
.main-directory-box h3 {
  font-size: 16px;
  margin-top: 0;
}
.directory-people-slider .owl-nav.disabled {
  display: block;
  text-align: center;
}
