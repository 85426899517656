@charset "utf-8";

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
    height: 650px;
    overflow-x: auto;
  }
  .chat-app .chat-history {
    height: 600px;
    overflow-x: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
    height: 480px;
    overflow-x: auto;
  }
  .chat-app .chat-history {
    height: calc(100vh - 350px);
    overflow-x: auto;
  }
}

@media only screen and (max-width: 1480px) {
  .personal-social-detail p {
    font-size: 13px;
  }
  .personal-social-detail {
    max-width: 70%;
  }
  .personal-social-detail {
    max-width: 55%;
  }
  .generic_feature_list {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1366px) {
  .main-directory-wrapper {
    max-width: 100%;
  }
  /* .main-directory-box {
    padding: 10px 25px;
  } */
  .form-control {
    font-size: 11px;
  }
  .inline-label {
    width: 49%;
  }
  .form-list.w-75 .inline-label {
    width: 40%;
  }
  .form-list.w-75 .btn-bar.leftbtn {
    margin-top: 0;
  }
  .form-list.w-75 .btn-primary {
    padding: 0.5rem 1.4rem;
  }
}
@media only screen and (max-width: 1280px) {
  .selected-plan li .inline-box {
    width: 45%;
  }
  .inline-small {
    width: 40%;
  }
  .user-detail {
    width: 100%;
    margin-top: 60px;
  }
  .preview-wrapper {
    max-width: 100%;
  }
  .personal-social-detail {
    height: auto;
  }
  .personal-social-detail {
    max-width: 50%;
  }
  .personal-social-detail {
    max-width: 45%;
    margin-left: 8px;
  }
  .prsonal-list-icon.blue-text {
    padding-right: 5px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
    font-size: 20px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
    font-size: 40px;
    line-height: 45px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
    font-size: 20px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_head_content {
    margin: 0 0 25px 0;
  }
  #generic_price_table .generic_content .generic_head_price .generic_head_content .head {
    padding-top: 25px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
    border-width: 55px 1411px 23px 391px;
  }
  #generic_price_table .generic_content .generic_feature_list ul li {
    font-size: 16px;
    padding: 12px 0;
  }
  .generic_feature_list {
    padding: 0 12px;
  }
  #generic_price_table .generic_content .generic_price_btn a {
    font-size: 14px;
    padding: 10px 25px;
  }
  /* .directory-profile {
    width: 120px;
    height: 120px;
  } */
  /* .main-directory-box h3 {
    font-size: 21px;
  }
  .main-directory-box h5 {
    font-size: 16px;
  } */
  #generic_price_table .generic_content .generic_feature_list ul.selected-plan,
  .bill-box {
    padding: 0 20px;
  }
  .bill-box .inline {
    min-width: 80px;
  }
  .selected-plan li .inline-box {
    width: 50%;
  }
  .generic_feature_list .selected-plan h2.inline-box {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1199px) {
  .blank-bio {
    height: 280px;
  }
  .bio-detail h1 {
    font-size: 25px;
  }
  /* .directory-people-slider h5,
  .main-directory-box h5 {
    font-size: 16px;
  }
  .directory-people-slider p,
  .main-directory-box p {
    font-size: 13px;
  } */
  .card .card-body,
  .card-light .card-body {
    padding: 1.25rem 0.5rem;
  }
  .form-control {
    padding: 0.6rem 0.5rem;
  }
  .card,
  .card-light {
    padding: 10px 8px;
  }
}
@media only screen and (max-width: 1150px) {
  /* .shorter-wrapper.new-shorter-design .main-directory-box {
    min-height: 300px;
  } */
  .form-list.w-75 {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1100px) {
  .main-directory-box .shorter-profile img {
    height: inherit;
    max-width: 100%;
  }
  .user-detail {
    /* margin-top: 20px; */
  }
  span.label-detail {
    /* max-width: 66%; */
  }
  .personal-social-detail h3 {
    font-size: 18px;
  }
  ul.listing-bottom.setting-listing .prsonal-list-icon.blue-text {
    width: 100%;
    text-align: right;
  }
  .personal-social-detail {
    max-width: 70%;
  }
  .center-heading h1 {
    font-size: 1.6rem;
  }
  .center-heading.black-text.mt-5 {
    margin-top: 1rem;
  }
  #generic_price_table .container {
    padding: 0;
  }
  #generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
    font-size: 18px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
    font-size: 28px;
    line-height: 35px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
    font-size: 20px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_price_tag {
    padding: 0 0 10px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
    border-width: 46px 1411px 23px 391px;
  }
  #generic_price_table .generic_content .generic_feature_list ul li {
    font-size: 14px;
  }
  #generic_price_table .generic_content .generic_feature_list ul li .fa {
    padding: 0 4px;
  }
  .thumb-two {
    left: 9%;
    top: -90px;
  }
  .team-detail {
    max-width: 450px;
  }
  .carousel-item img {
    height: auto;
    max-width: inherit;
  }
  .white-box.fron-end-wrapper .container-fluid.pt-5 {
    padding-top: 0 !important;
  }
  .form-list.w-75 {
    width: 100% !important;
  }
  ul.badge-form {
    width: 80%;
  }
}

@media only screen and (max-width: 1050px) {
  .form-list .form-group.form-floating-label.inline-label {
    width: 100%;
  }
  .form-list.w-75 .btn-bar.leftbtn,
  .form-list.w-75 .btn-primary {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .thumb-two {
    left: 4%;
    top: -100px;
  }
  .thumb-four {
    right: 6%;
    top: -85px;
  }
}
@media only screen and (max-width: 991px) {
  .bio-detail h1 {
    font-size: 20px;
    padding: 20px;
  }
  .blank-bio {
    height: 198px;
  }
  .blank-bio .bio-detail {
    bottom: 0;
  }
  .all-tab-area a {
    font-size: 14px;
    min-width: inherit;
  }

  .main-panel {
    margin-top: 55px;
  }
  .profile-header {
    background-size: cover;
  }
  .user-detail {
    margin-top: 5px;
  }
  .main-panel > .content {
    background: #fff;
  }
  /*.navbar-header{ position:static;}
.main-panel{ margin-top:50px;}
.logo-header{ width:250px !important;}
.navbar-header .navbar-nav{ justify-content:flex-end;}
#search-nav{
	    margin-bottom: -42px !important;
    background: #fff;
    z-index: 1;
	}*/

  .short-link-description {
    font-size: 12px;
  }
  .w-50.mobile-full-width.mx-auto.mt-5,
  .form-list.w-50.mobile-full-width.mx-auto {
    width: 80% !important;
  }
  .card-body h1 {
    font-size: 1.4rem;
  }
  ul.nav-list li a {
    font-size: 14px;
    padding: 5px 10px;
  }
  .btn {
    padding: 0.5rem 1.2rem;
  }
  .number-value {
    font-size: 40px;
    width: 55px;
    line-height: 45px;
  }
  span.number-dot img {
    max-width: 35px;
    padding-top: 8px;
  }
  .text-box {
    padding-left: 10px;
    padding-top: 0;
  }
  .work-wrapper {
    padding: 50px 0;
  }
  .work-row .row::after {
    left: 13.8%;
    height: 59%;
  }
  .work-row .row:before {
    left: 14%;
    width: 64%;
  }
  .work-row .row.two-box::after {
    right: 19.8%;
  }
  .work-row .row.three-box:last-child:after {
    height: 30%;
  }
  h2,
  .h2 {
    font-size: 1.2rem;
  }
  .bio-detail h3 {
    font-size: 14px;
  }
  .inlineview {
    width: 48%;
  }
  img.workh {
    left: inherit;
    right: 0;
  }
  .banner-box h2 {
    font-size: 2rem;
  }
  .how-work-left a.btn {
    width: 75%;
    margin: 15px 0 0;
  }
  p.lead.mb-0.mt-4 {
    margin-top: 0px !important;
  }
  .social-links-header.mt-5 {
    margin-top: 25px !important;
    margin-bottom: 15px;
  }
  .img-section {
    min-height: 200px;
  }
  .detail-section h4 {
    font-size: 18px;
  }
  /*frontend css start*/
  a.nav-icon {
    display: block;
    float: right;
    padding: 5px 10px;
    margin-top: 12px;
    font-size: 20px;
  }
  .frontend-navigation {
    position: relative;
  }
  .frontend-navigation ul.nav-list {
    position: absolute;
    width: 100%;
    background: #eee;
    text-align: right;
    left: 0;
    padding: 25px;
    top: 55px;
    display: none;
  }
  .nav-list.collapse.show {
    display: block;
  }
  ul.nav-list li {
    display: block;
    padding: 15px;
  }
  .white-box.fron-end-wrapper.pt-5 {
    padding-top: 0 !important;
  }
  .white-box.fron-end-wrapper .card.full-height {
    box-shadow: none;
  }
  .white-box.fron-end-wrapper .card-body {
    padding: 0;
  }
  .white-box.fron-end-wrapper .center-heading h1 {
    margin-top: 0;
  }
  .row.mt--2 {
    margin: 0 !important;
  }
  .all-tab-area a {
    padding: 10px 14px;
  }
  .main-directory-wrapper.directory-width-mob .col-md-3 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  ul.badge-form {
    width: 100%;
  }
  .btn-bar {
    margin-top: 10px;
  }
  .claim-now-area .text-input {
    width: 55%;
    padding: 5px 0;
  }
  .claim-now-area {
    padding-left: 6px;
  }
  .claim-now-area a.btn {
    padding: 12px 12px;
  }
  .card-body.px-5 {
    padding: 0 !important;
  }
  .heading-blog {
    padding-top: 10px;
  }
  .blog-detail {
    bottom: 8px;
    padding: 0 10px;
  }
  .blog-box {
    min-height: 160px;
  }
  .blog-box.width-full {
    height: 340px;
  }
  .blog-text {
    padding: 20px 0px 0;
    width: 100%;
  }
  .recent-area-box .col-md-4.order-md-1 .blog-box.mb-3 {
    min-height: 340px;
  }
}

@media only screen and (max-width: 890px) {
  .team-detail {
    max-width: 400px;
  }
  .main-directory-box h3 {
    font-size: 18px;
  }
  .main-directory-box h5 {
    font-size: 14px;
  }
  .about-detail {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
  .about-detail .inline-sec.social a {
    margin-right: 5px;
  }
  .thumb-two {
    left: 0%;
    top: -100px;
  }
  .thumb-four {
    right: 2%;
  }
  .profile-left {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .profile-left,
  .user-detail {
    display: block;
    width: 100%;
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .blank-bio {
    height: 250px;
  }
  .form-list.w-75,
  .form-group.w-50 {
    width: 100% !important;
  }
  .donation-box h2 {
    font-size: 20px;
  }
  .donation-box a {
    margin: 0 0 0 14px;
  }
  .donation-box a img {
    max-height: 25px;
  }
  .user-detail h3 {
    font-size: 24px;
  }
  ul.listing-bottom.linksort li {
    width: 100%;
  }
  .card.full-height {
    /*margin-top: 90px;*/
  }
  .mobile-full-width {
    width: 80%;
    max-width: inherit;
  }
  .minus-margin {
    margin: 0;
  }
  ul.listing-bottom li {
    width: 100%;
  }
  .generic_feature_list {
    text-align: left;
    padding: 0 50px;
  }

  #generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
    border-width: 70px 1411px 23px 391px;
  }
  #generic_price_table .generic_content .generic_head_price .generic_price_tag {
    padding: 10px 0 10px;
  }
  .generic_content.clearfix {
    margin-bottom: 50px;
  }
  .all-tab-area a {
    min-width: inherit;
    width: auto;
    padding: 10px 15px;
  }
  .chat-app .people-list.open {
    left: 0;
  }
  .chat-app .chat {
    margin: 0;
  }
  .chat-app .chat .chat-header {
    border-radius: 0.55rem 0.55rem 0 0;
  }
  .chat-app .chat-history {
    height: 300px;
    overflow-x: auto;
  }

  .singal-chat-box {
    width: 90%;
  }
  #custCarousel .carousel-indicators {
    position: static;
    padding-top: 30px;
    height: 130px;
    justify-content: inherit;
    display: block;
  }
  #custCarousel .carousel-indicators > li {
    position: static;
    margin: 0 10px;
    width: auto;
  }
  .team-detail {
    max-width: 100%;
    margin-top: 0;
  }
  .about-bottom .shorter-profile {
    max-width: 150px;
    display: block;
    margin: 0 auto;
  }
  .list-inline-item a {
    width: 100px;
    height: 100px;
  }
  .black-text.heading-area.pt-5.mt-5 {
    margin: 0 !important;
    padding: 0 !important;
  }
  .col-md-6.how-work-left.pt-5 {
    padding: 25px 0 !important;
    text-align: center;
  }
  .work-row .row:before,
  .work-row .row::after {
    display: none;
  }
  .number-box.float-left {
    display: block;
    float: none;
    width: 100%;
    text-align: center;
  }
  span.number-dot {
    display: none;
  }
  .text-box {
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  .work-wrapper {
    padding: 50px 0 0;
  }
  .work-row .row {
    padding: 0 !important;
  }
  .dextop {
    display: none !important;
  }
  .mob {
    display: block !important;
  }
  .bio-column {
    margin-bottom: 30px;
  }
  .inlineview.righview,
  .inlineview {
    width: 100%;
    text-align: center;
    padding-top: 15px;
  }
  .bio-section {
    padding-bottom: 0;
  }
  .footer-logo img {
    max-width: 120px;
  }
  .faq-content {
    margin: 25px 0 15px 60px;
  }
  .banner-box h2 {
    padding-top: 35px;
    text-align: center;
  }
  .banner-box .col-md-6.text-right {
    text-align: center !important;
  }
  .preview-wrapper {
    /* width: 75%; */
  }
  a.nav-pagi {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
  }
  /*frontend css start*/
  ul.inline-sec {
    width: 50% !important;
    padding-left: 13%;
  }
  ul.inline-sec.social {
    margin: 0 auto;
    width: 100% !important;
    text-align: center;
    padding-left: 0;
  }
  footer .col-md-8.right-margn {
    padding-left: 15px;
  }

  /*****frontend css******/
  .carousel-caption {
    position: initial;
    z-index: 10;
    padding: 3rem 2rem;
    color: rgba(78, 77, 77, 0.856);
    text-align: center;
    font-size: 0.7rem;
    font-style: italic;
    font-weight: bold;
    line-height: 1.5rem;
  }
  .carousel-caption img {
    width: 4rem;
    border-radius: 4rem;
    margin-top: 1rem;
  }

  #image-caption {
    font-style: normal;
    font-size: 0.6rem;
    margin-top: 0.5rem;
  }
  .main-directory-wrapper.directory-width-mob .col-md-3 {
    width: 100%;
    max-width: 100%;
    flex: auto;
    margin-bottom: 20px;
  }
  .how-work-left a.btn {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .center-heading h1 {
    margin-top: 0;
  }
  .generic_feature_list .bill-box {
    margin-top: 50px;
  }
  .generic_feature_list .bill-box {
    text-align: center;
  }
  #generic_price_table .generic_content .generic_feature_list ul.selected-plan,
  .bill-box {
    padding: 0 !important;
  }

  .col-md-6 .generic_feature_list {
    padding: 0 25px;
  }

  .personal-directory .user-detail {
    display: block;
    width: 100%;
  }
  .preview-wrapper.mt-5.pt-5.personal-directory {
    padding-top: 0 !important;
  }

  ul.listing-bottom.minus-margin li {
    width: 100%;
    display: block;
  }
  .container.pt-5 {
    padding-top: 0 !important;
  }
  .container.pt-5 .row.mt--2 {
    margin-top: 0 !important;
  }
  .feature-detail {
    max-width: 100%;
  }
  .feature-detail h1 {
    margin-top: 20px;
  }
  .feature-row {
    margin-bottom: 30px;
  }
  .feature-detail h1 {
    font-size: 26px !important;
  }
  .signup-left-box {
    text-align: center;
  }
  .signup-text p {
    max-width: 100%;
  }
  /* .shorter-wrapper.new-shorter-design .main-directory-box {
    min-height: inherit;
    margin-bottom: 50px;
  } */
  .contact-detail {
    width: 100%;
  }
  .claim-now-area .text-input {
    width: 68%;
    padding: 14px 0px;
  }
  .footer-logo {
    text-align: center;
  }
  .footer {
    padding: 50px 0 40px;
  }
  .footer-logo img {
    max-width: 95px;
    margin-bottom: 10px;
  }
  .heading-blog {
    font-size: 26px;
    padding-bottom: 0;
  }
  .col-md-4.text-right.mt-4 {
    margin-bottom: 15px;
    margin-top: 10px !important;
  }
  .recent-area-box .col-md-4.order-md-1 .blog-box.mb-3,
  .blog-box {
    min-height: inherit;
  }
  .blog-row {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 680px) {
  .profile-left,
  .user-detail {
    display: block;
    width: 100%;
    padding: 0;
  }
  .user-detail h3 {
    text-align: center;
  }
  #custCarousel .carousel-indicators > li {
    margin: 0 5px;
  }

  .list-inline-item a {
    width: 85px;
    height: 85px;
  }
  img.workh {
    display: none;
  }
  .feature-row {
    margin-bottom: 30px;
  }
  section.feature-wrapper.section-space {
    padding-bottom: 0;
  }
  .sidebar .nav > .nav-item a,
  .sidebar[data-background-color='white'] .nav > .nav-item a {
    display: block;
  }
  .sidebar .nav > .nav-item a i,
  .sidebar[data-background-color='white'] .nav > .nav-item a i {
    display: block;
    float: none;
    width: 100%;
  }
  .sidebar .nav > .nav-item a p {
    display: block;
    width: 100%;
    margin: 0px 0 0;
    text-align: center;
    word-break: break-word;
    white-space: inherit;
  }
  .sidebar,
  .sidebar[data-background-color='white'] {
    width: 180px;
  }
  .nav_open .main-panel,
  .nav_open .main-header {
    -webkit-transform: translate3d(180px, 0, 0);
    -moz-transform: translate3d(180px, 0, 0);
    -o-transform: translate3d(180px, 0, 0);
    -ms-transform: translate3d(180px, 0, 0);
    transform: translate3d(180px, 0, 0) !important;
  }
  span.label-detail {
    max-width: 100%;
    width: 100%;
  }
  ul.preview-list {
    max-width: inherit;
    width: 100%;
  }
  .preview-wrapper {
    width: 100%;
  }
  .white-box.mb-5 .row.mt--2 {
    width: 100%;
  }
  .profile-box {
    margin-top: 0;
  }
  .personal-directory .user-detail {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
  a.nav-pagi.prev {
    margin-left: -15px;
  }
  a.nav-pagi.next {
    margin-right: -15px;
  }
}
@media only screen and (max-width: 640px) {
  .table-head-bg-primary thead {
    border: 0 !important;
  }
  .responsiveTable td .tdBefore {
    color: #1572e8;
  }
  .table td,
  .table th {
    padding: 9px 25px !important;
    height: auto;
  }
  .clearfix.card-header.text-center.w-100.mb-4.mt-4.heading-section {
    margin-bottom: 5px !important;
  }
  .inline-small {
    width: 36%;
  }
  .sidebar.sidebar-style-2 .nav .nav-item {
    padding: 0 15px 0 6px;
  }
  .sidebar .nav > .nav-item a i,
  .sidebar[data-background-color='white'] .nav > .nav-item a i {
    margin-right: 8px;
  }

  ul.nav.nav-primary .btn {
    padding: 0.65rem 5px;
  }
  h1,
  .h1 {
    font-size: 1.6rem;
  }
  .inlineview.righview span {
    font-size: 26px;
  }
  a.workbtn {
    font-size: 14px;
  }
  .howwork-bottom {
    margin-bottom: 50px;
  }
  .claim-now-area {
    width: 100%;
    padding-left: 10px;
  }
  .claim-now-area .text-input {
    padding: 9px 0;
  }
  .footer-logo img {
    max-width: 80px;
  }

  .payment-detail li {
    display: block;
    width: 100%;
    font-size: 14px;
  }
  .payment-detail li:nth-child(2n) {
    text-align: left;
    padding-bottom: 10px;
  }
  .payment-detail {
    padding: 0;
  }
  .payment-box {
    max-width: 100%;
    width: 100%;
  }
  .payment-detail .model-h li {
    display: inline-block;
    vertical-align: middle;
    width: 48%;
    color: #090;
  }
  .payment-detail .model-h li:nth-child(2n) {
    padding-bottom: 0;
    text-align: right;
  }
  h1,
  .h1 {
    font-size: 1.2rem;
  }
  .badge-form li {
    width: 40%;
  }
  .claim-now-area a.btn {
    padding: 8px 12px;
  }
  tr.bgsection:nth-child(2n + 1) td,
  tbody.bgsection tr:nth-child(2n + 1) td {
    background: #e8e8e8;
  }
  .responsiveTable tbody tr {
    border: 1px solid #eaeaea !important;
    padding: 0 !important;
  }
  .link-Shorttable tbody tr {
    padding: 25px 0px !important;
  }
}
@media only screen and (max-width: 580px) {
  .sidebar,
  .sidebar[data-background-color='white'] {
    width: 130px;
  }
  .nav_open .main-panel,
  .nav_open .main-header {
    -webkit-transform: translate3d(130px, 0, 0);
    -moz-transform: translate3d(130px, 0, 0);
    -o-transform: translate3d(130px, 0, 0);
    -ms-transform: translate3d(130px, 0, 0);
    transform: translate3d(130px, 0, 0) !important;
  }
  ul.nav.nav-primary li.mx-4.mt-2 {
    margin-left: 4px !important;
    margin-right: 8px !important;
  }
  .btn .btn-label {
    margin-right: 0 !important;
  }
  .mob-block {
    display: block;
  }
  .personal-social-detail {
    max-width: 100%;
    margin-left: 0px;
  }
  .social-links .btn-admin .lab-text {
    font-size: 13px;
  }
  /* ul.listing-bottom.linksort .prsonal-list-icon {
    float: none;
    width: 100%;
    margin-top: 8px;
  } */
  .listurl {
    padding: 8px 15px;
  }
  .analytic-box {
    width: 45%;
  }

  .form-group.inline-box {
    display: block;
  }
  .inline-small {
    width: 48%;
  }
  .form-group.inline-box .btn-bar.leftbtn,
  .form-group.inline-box .btn-bar.leftbtn a.btn.btn-primary {
    margin-left: 0;
  }

  .badge-form li {
    width: 100%;
    margin-right: 0;
  }
  .modal-title {
    font-size: 20px;
  }
  .billing-detail,
  #generic_price_table {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .report-detail.w-75.mx-auto {
    width: 100% !important;
  }
  .generic_feature_list .selected-plan h2.inline-box {
    font-size: 16px;
  }

  .bill-box .inline {
    min-width: 100%;
    display: block;
    padding: 8px 0;
  }

  #custCarousel .carousel-indicators {
    height: 95px;
    padding-top: 10px;
  }
  .inline-small {
    width: 100%;
  }
  .center-heading h1 {
    font-size: 1.4rem;
  }
  .w-50.mobile-full-width.mx-auto.mt-5,
  .form-list.w-50.mobile-full-width.mx-auto {
    width: 100% !important;
  }
  .short-link-description {
    font-size: 11px;
  }
  .short-link-description img {
    width: 22px;
    margin: auto 5px;
  }
  .white-box.mb-5 {
    margin-bottom: 20px !important;
  }

  span.label-box {
    width: 100%;
    display: block;
  }
  span.label-detail {
    max-width: 100%;
    display: block;
    margin-top: 5px;
  }
  .socialicon span {
    padding: 5px 8px;
    margin-right: 5px;
  }
  .btn {
    padding: 0.45rem 1rem;
  }
  .btn-lg {
    padding: 9.5px 20.5px;
  }
  .user-detail h3 {
    font-size: 22px;
  }
  .analytic-box {
    width: 100%;
  }
  .center-heading h4 {
    font-size: 1rem;
    margin-top: 10px;
  }
  .form-group,
  .form-check {
    padding: 10px 0;
  }
  .card .card-body,
  .card-light .card-body {
    padding-left: 10px;
    padding-right: 10px;
  }
  .billing-detail {
    font-size: 14px;
  }
  .billing-detail .inline {
    padding: 0 5px;
  }

  .singal-chat-box {
    margin: 20px 0;
  }
  .chat.singal-chat-box .chat-header {
    padding: 10px 20px;
    text-align: center;
  }
  .chat.singal-chat-box .chat-header img {
    width: 65px;
  }
  .chat.singal-chat-box h6 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .chat-detail-box {
    padding: 30px 20px;
  }
  .chat-detail-box h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .chat .chat-header .chat-about {
    width: 100%;
    padding: 5px 0;
  }

  /****7-8-21-****/
  span.icon-span {
    display: none;
  }
  .inline-icons span {
    padding: 0;
  }
  .list-inline-item a {
    width: 60px;
    height: 60px;
  }
  /*frontend css start*/
  ul.inline-sec {
    padding-left: 5%;
  }
  .feature-detail h1 {
    font-size: 14px !important;
    margin-bottom: 10px;
  }
  .claim-now-area a.btn {
    padding: 16px 12px;
  }
  .claim-now-area .text-input {
    width: 58%;
  }
  ul.inline-sec.social a {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
  .card,
  .card-light {
    padding: 10px 15px;
  }
  .heading-blog {
    font-size: 15px;
  }
  .card,
  .card-light {
    margin-bottom: 10px;
  }
  .card.full-height {
    padding: 0;
  }
  .responsiveTable td.pivoted {
    padding-left: calc(36% + 10px) !important;
  }
}
@media only screen and (max-width: 460px) {
  .social-links .btn-admin {
    display: block;
  }
  /* .branding-model .swap-box {
    float: none;
    width: 100%;
    text-align: left;
  }
  .modal-body.branding-model .form-floating-label .placeholder {
    position: static;
    width: 100%;
  }
  .branding-model .lockimages,
  .branding-model .swap-btn-bar-admin {
    float: left;
  }*/
  .modal-body .form-floating-label .placeholder {
    max-width: 65%;
  }
  .modal-title {
    font-size: 18px;
  }
  .frontend-navigation {
    padding: 5px 8px;
  }
  .logo-box {
    width: 100%;
    margin-right: 0;
    overflow: hidden;
    text-align: center;
  }
  .navbar-brand {
    padding-top: 0;
  }

  .btn {
    padding: 0.45rem 0.5rem;
  }
  .form-group label,
  .form-check label {
    white-space: break-spaces;
    font-size: 14px;
  }
  .signup-text.text-center.mb-5 {
    margin-bottom: 20px !important;
  }
  .signup-text h1 {
    font-size: 24px;
  }
  a.nav-icon {
    float: left;
  }
  .frontend-navigation ul.nav-list {
    top: 110px;
  }
  .input-group-prepend.position-absolute {
    right: 0;
    top: 16px;
  }
  .input-group-prepend.position-absolute .input-group-text {
    font-size: 18px;
  }
  .form-floating-label .placeholder {
    font-size: 13px !important ;
    padding-top: 10px;
  }
  .billing-detail,
  #generic_price_table {
    padding-left: 10px;
    padding-right: 10px;
  }
  .col-md-6 .generic_feature_list {
    padding: 0 14px;
  }
  .generic_feature_list .selected-plan h2.inline-box {
    font-size: 14px;
  }
}
@media only screen and (max-width: 380px) {
  .profile-box {
    width: 130px;
    height: 130px;
    margin-top: 0;
  }

  .donation-box h2 {
    display: block;
    margin-bottom: 12px;
  }
  .donation-box a {
    margin: 0 12px 0 0px;
  }
  .socialicon span {
    display: inline-block;
    width: 45%;
    margin-bottom: 8px;
  }
  .socialicon {
    text-align: left;
  }
  ul.preview-list li {
    font-size: 12px;
  }

  /*******7-8-21***********/
  .other-detail-list li {
    display: block;
    margin: 0;
    width: 100%;
  }
  .signup-text h1 {
    font-size: 20px;
  }
  .navbar-brand {
    margin-right: 0;
    margin-top: 8px;
  }
  .bg-claim {
    padding: 24px 0px;
  }
  .claim-now-area .text-input {
    width: 100%;
    padding: 8px;
  }
  .claim-now-area {
    padding: 0;
  }
  .claim-now-area a.btn {
    width: 100%;
    padding: 10px 12px;
  }
  ul.inline-sec.social a {
    width: 25px;
    height: 25px;
    font-size: 14px;
    line-height: 26px;
  }
  ul.inline-sec.social a:last-child {
    margin-right: 0;
  }
  .form-group.form-floating-label label.placeholder {
    font-size: 11px !important;
  }
}

@media (min-width: 768px) {
  .userProfile {
    color: #fff;
    display: flex;
    text-align: center;
    position: absolute;
    top: -100px;
    align-items: center;
  }
  .md-header-area .profile-box {
    margin: 0 auto;
  }
  .md-usernm {
    text-align: center;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .user-dt-head {
    margin-top: 50px;
  }
}

@media (max-width: 640px) {
  .link_with {
    display: block !important;
    width: 100% !important;
  }
}
