.alert-area {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9999;
}

.alert-container {
  width: max-content;
  margin-bottom: 2px;
  animation: slide-in-left 0.4s ease;
}

.alert-body {
  display: flex;
  border-radius: 5px;
  padding: 5px 0;
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  opacity: 0.8;
}
.alert-icon,
.alert-message,
.alert-actions {
  padding: 5px;
}
.alert-icon {
  border-right: 1px solid gray;
}
.alert-actions {
  border-left: 1px solid gray;
}
.alert-actions .fa{
  font-size: 15px;
}
.alert-success {
  border: 1px solid rgb(0, 56, 0);
    background-color: #28a745;
    align-items: center;
    font-size: 12px;
    width: 155px;
    justify-content: space-between;
    opacity: 1;
    height: 40px;
}
.alert-warning {
  border: 1px solid rgb(116, 75, 0);
  background-color: orange;
  align-items: center;
  font-size: 12px;
  width: 155px;
  justify-content: space-between;
  opacity: 1;
  height: 40px;
}
.alert-danger {
  border: 1px solid #7a2d00;
  background-color: #fc5f04;
  align-items: center;
  font-size: 12px;
  width: 155px;
  justify-content: space-between;
  opacity: 1;
  height: 40px;
}
.alert-error {
  border: 1px solid rgb(119, 0, 0);
  background-color: rgb(255, 2, 2);
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  opacity: 1;
}
.alert-default {
  border: 1px solid #003f66;
  background-color: #1189d1;
  align-items: center;
  font-size: 12px;
  width: 155px;
  justify-content: space-between;
  opacity: 1;
  height: 40px;
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
