@charset "utf-8";
body {
  color: #222;
  font-family: 'Poppins', sans-serif;
}
img {
  max-width: 100%;
}
ul {
  padding: 0;
}
.color-bio {
  color: #1c3faa;
}
.icon-options-vertical {
  display: block;
  background: url(../images/menu-threedots.png);
  padding: 10px;
  width: 15px;
  height: 50px;
  background-repeat: no-repeat;
}
.profile-header::before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
  border-radius: 20px;
}

.profile-header {
  background: url(../images/banner.png) no-repeat;
  background-size: cover;
  position: relative;
  background-position: top;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  height: 260px;
  display: flex;
  align-items: flex-end;
  justify-content: end;
}
.white-box {
  background: #fff;
  /* box-shadow: 0px 0px 10px 10px #eee;
  border-radius: 20px 20px 20px 20px; */
}
.profile-edit-wrapper {
  position: absolute;
  right: 25px;
  bottom: 60px;
}
.btn-white.btn-border:hover {
  background: #fff !important;
  color: #1572e8 !important;
}
.profile-box {
  width: 125px;
  height: 125px;
  position: relative;
  /* margin: -25px auto 20px; */
}
.user-profile {
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #1572e8;
  text-align: center;
}
.file-btn {
  position: absolute;
  right: 0px;
  bottom: 16px;
  z-index: 99;
}
.file-btn img {
  max-width: 30px;
}

.profile-left {
  display: flex;
  /* vertical-align: top;
  width: 32%; */
  text-align: center;
}
.user-detail {
  /* display: inline-block; */
  vertical-align: top;
  /* width: 60%; */
  margin-top: 50px;
  text-align: left;
}
.user-detail ul {
  list-style-type: none;
}
.preview-wrapper {
  /* max-width: 80%; */
  /* margin: 0 auto; */
}
.socialicon {
  margin: 20px 0;
  text-align: center;
}
.socialicon span {
  margin-right: 15px;
  font-size: 12px !important;
  display: inline-block;
  padding: 5px 10px;
}
.socialicon span:first-child {
  background: #dfe6f5;
  color: #4267b2;
}
.socialicon span:nth-child(2) {
  background: #d2ecfc;
  color: #1da1f2;
}
.socialicon span:nth-child(3) {
  background: #f6d0e2;
  color: #d23374;
}
.socialicon span:nth-child(4) {
  background: #c0d7e2;
  color: #0077b5;
}
.notif-box .notif-center .notif-list {
  display: flex;
  color: #4d585f;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  margin: 5px 0px;
}
.notif-box .notif-center .notif-list:hover {
  background-color: #eee;
  border-radius: 5px;
}
.messages-notif-box .notif-center .notif-content {
  width: 35%;
}
.notif-box .notif-center .notif-content {
  width: 70%;
}
.notif-btn {
  margin-top: 12px;
}
.modal-body .btn {
  margin: 15px 10px 0;
}
.modal-title {
  font-size: 15px;
  /* color: #000; */
  color: #193999 !important;
  font-weight: bold;
}
.close {
  color: #1572e8;
}
.other-detail-list {
  display: block;
  width: 100%;
  padding: 0;
}
.other-detail-list li {
  list-style-type: none;
  font-size: 12px;
  margin: 8px 3px;
  position: relative;
}
.other-detail-list li:before {
  position: absolute;
  content: '\f0da';
  left: 0;
  top: 6px;
  font: normal normal normal 14px/1 FontAwesome;
  color: #1572e8;
}
.other-detail-list li:first-child:before {
  display: none;
}
.other-detail-list h3 {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 15px;
}
.other-detail-list li a {
  display: block;
  padding-left: 14px;
  font-size: 12px;
}
.other-detail-list li a:hover {
  color: #0d85b9;
  text-decoration: none;
}
.user-detail h3 {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #1572e8;
}
ul.preview-list {
  max-width: 700px;
  /* margin: 0 auto; */
  text-align: left;
  margin-bottom: 0px;
  margin-top: 50px !important;
}
ul.preview-list li {
  list-style-type: none;
  line-height: 30px;
  font-size: 11px;
}
span.label-box {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  font-weight: bold;
}
span.label-detail {
  display: inline-block;
  vertical-align: top;
  /* max-width: 75%; */
}
span.label-box .fa {
  width: 22px;
  font-size: 12px;
}
span.label-box i.fa.fa-envelope {
  font-size: 11px;
}
.donation-box a {
  display: inline-block;
  margin: 0 5px;
}
.donation-box a img {
  max-height: 20px;
}
.donation-wrapper {
  text-align: center;
}
.donation-wrapper .donation-box {
  max-width: inherit;
  display: inline-block;
}
.donation-box {
  max-width: 700px;
  text-align: center;
  /* background: #eee; */
  margin: 10px auto;
  /* padding: 15px; */
}
.donation-box h2 {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  margin-right: 15px;
  margin: 0;
}
.blue-text,
.blue-text h6 {
  color: #1c3faa;
  font-weight: bold;
}
.listing-bottom {
  margin-bottom: 0;
}
ul.listing-bottom li {
  list-style-type: none;
  margin-bottom: 10px;
}
ul.listing-bottom li:last-child {
  margin-bottom: 0;
}
ul.listing-bottom li label {
  display: block;
  width: 200px;
  font-weight: bold;
  font-size: 12px;
}
ul.listing-bottom li p {
  display: block;
  width: 100%;
}

.listurl {
  background: #e7edfd;
  border-radius: 7px;
  padding: 15px 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  border: 2px solid #cfd8f5;
}
.listurl-body {
  height: auto !important;
  padding: 15px 10px;
  background-color: #f2f7fb;
  margin: 10px 0px;
}
.dashboard-listurl input {
  background: #e4e6e8;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  font-size: 10px;
}
.dashboard-listurl label {
  width: 70px !important;
  font-size: 10px !important;
}
.dashboard .preview-wrapper {
  padding: 0 0 50px 0;
}
.personal-social-detail {
  max-width: 100%;
  margin-left: 22px;
  height: 80px;
}
.personal-social-detail {
  display: inline-block;
  vertical-align: middle;
}
.personal-social-detail h3 {
  font-size: 12px;
  color: #000;
  margin-bottom: 8px;
}
.personal-social-detail p {
  font-size: 11px;
  color: #838383;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.prsonal-list-icon {
  float: right;
  display: flex;
  align-items: center;
}
.col-md-6 .listing-bottom li {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
}
button.delbtn {
  border: none;
  background: none;
  color: red;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 23px;
}
ul.listing-bottom li p {
  margin-bottom: 0;
}
.listing-bottom {
  margin-bottom: 0;
}
.minus-margin {
  margin: 0 -2%;
}

ul.listing-bottom.biolink-list.minus-margin {
  margin: 0 auto;
  width: 100%;
}

.listing-bottom li {
  /* display: inline-block;
  vertical-align: top;
  width: 55%;
  margin: 0 2% 1rem; */
}
.social-links {
  margin-top: 25px;
}
.social-links .btn-admin {
  margin-bottom: 13px;
  border: none;
  background: #193999 !important;
  display: inline-block;
  vertical-align: top;
  margin-right: 13px;
  width: 35px;
  height: 35px;
  padding: 3px;
  border-radius: 50%;
}
.social-links .btn-admin img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  max-width: 24px;
}
.social-links .btn-admin .lab-text {
  vertical-align: middle;
  font-size: 12px;
  color: #000;
  display: inline-block;
}
.social-links .btn-admin .close {
  color: #000;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  line-height: 100%;
  opacity: 1;
  margin: 4px 0px 0px 18px;
}
.social-links .btn-admin .close i {
  display: block;
  color: #000;
}

ul.listing-bottom.linksort .prsonal-list-icon {
  margin-right: 22px;
  color: #0096e1;
  font-size: 12px;
  font-weight: bold;
  margin-top: 22px;
}
.analytic-box {
  margin-bottom: 80px;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  background: #f8fbff;
  border-radius: 0 16px 16px 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 13px;
  margin-bottom: 7px;
  font-family: K2D;
  font-size: 12px;
  line-height: 21px;
  color: #4b4279;
  width: 200px;
}
.center-heading {
  padding: 1rem 1.25rem;
  text-align: center;
}
.center-heading h1 {
  color: #1572e8;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 30px;
}
.center-heading h4 {
  font-weight: bold;
  margin-top: 8px;
  font-size: 1.2rem;
}

.short-link-description {
  position: relative;
  font-size: 12px;
}
.short-link-description span {
  display: block;
}
/*.short-link-description::before, .short-link-description::after{ position: absolute; content: ""; width: 50px; height: 60px;}
.short-link-description::before{ background: url(../img/quet.png) no-repeat; background-position: left;}
.short-link-description::after{ background: url(../img/quet-botto.png) no-repeat;background-position: right;} */

.swap-btn-bar-admin {
  margin: 0 auto;
  padding: 3px 5px;
  min-width: 44px;
  border-radius: 22px;
  display: inline-block;
  background: #e7edfd;
  /* margin-top: 20px; */
  float: right;
}
.swap-btn-bar-admin a {
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 30px;
  font-size: 12;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}

.swap-btn-bar-admin a.active {
  color: #fff;
  background-color: #1c3faa;
  font-weight: bold;
}
.links-action-btn {
  border: 0;
  background: none;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.links-action-btn i {
  padding: 0px 8px;
}
.card-body h1 {
  font-weight: bold;
}

.directory-box {
  text-align: center;
  display: block;
}
.big-title {
  font-size: 15px;
  font-weight: bold;
}

.inline-label {
  display: inline-block;
  vertical-align: top;
  width: 48%;
}
.profile-img-box {
  width: 60px;
  border-radius: 24%;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.setting-listing .listurl {
  padding: 10px 10px;
}
.setting-listing .personal-social-detail {
  height: auto;
}

.prsonal-list-icon.blue-text {
  margin-top: 14px;
  padding-right: 15px;
  font-weight: bold;
}
.prsonal-list-icon.blue-text i {
  font-size: 20px;
  font-weight: normal;
  padding-left: 5px;
}
.black-text {
  color: #000;
}
.center-heading.black-text h1 {
  color: #000;
  font-size: 20px !important;
  color: #1c3faa;
}
.btn-lg {
  min-width: 150px;
}

.bg-primary {
  color: #fff !important;
}
.note {
  display: block;
}
.heading-section {
  background: none;
  border: 0;
}
.heading-section h1 {
  font-weight: bold;
}
.inline-box {
  display: inline-block;
  vertical-align: middle;
}
.inline-small {
  width: 35%;
}

/************/

/*PRICE COLOR CODE START*/
#generic_price_table .generic_content {
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%);
}

/* #generic_price_table .generic_content .generic_head_price {
  background-color: #f6f6f6;
} */

/* #generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
  border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
} */

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
  color: #525252;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
  color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  color: #484848;
}

#generic_price_table .generic_content .generic_feature_list ul li span {
  color: #414141;
}
/* #generic_price_table .generic_content .generic_feature_list ul li:hover {
  background-color: #e4e4e4;
  border-left: 5px solid #0062cc;
} */

/* #generic_price_table .generic_content .generic_price_btn a {
  border: 1px solid #0062cc;
  color: #0062cc;
}

#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg {
  border-color: #0062cc rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #0062cc;
  color: #fff;
} */

#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span {
  color: #fff;
}

/* #generic_price_table .generic_content:hover .generic_price_btn a,
#generic_price_table .generic_content.active .generic_price_btn a {
  background-color: #0062cc;
  color: #fff;
} */
/* #generic_price_table {
  margin: 50px 0 50px 0;
  font-family: 'Raleway', sans-serif;
} */
.row .table {
  padding: 28px 0;
}

/*PRICE BODY CODE START*/

#generic_price_table .generic_content {
  overflow: hidden;
  position: relative;
  text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
  margin: 0 0 20px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content {
  margin: 0 0 10px 0;
}

/* #generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
  border-style: solid;
  border-width: 90px 1411px 23px 399px;
  position: absolute;
} */

#generic_price_table .generic_content .generic_head_price .generic_head_content .head {
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag {
  padding: 0 0 20px;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price {
  display: block;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 40px;
  padding: 0;
  vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 3px;
  vertical-align: bottom;
  padding: 0px 10px;
}
.upgrade-area .lockimages {
  margin: 0 30px;
}
.upgrade-area .selected-plan li:hover {
  background-color: #e7e7e7;
  border-radius: 5px;
}
#generic_price_table .generic_content .generic_feature_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  font-size: 12px;
  padding: 15px 0;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table .generic_content .generic_feature_list ul li:hover {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
#generic_price_table .generic_content .generic_feature_list ul li .fa {
  padding: 0 10px;
}
#generic_price_table .generic_content .generic_price_btn {
  margin: 20px 0 32px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

#generic_price_table .generic_content .generic_price_btn a {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  outline: medium none;
  padding: 10px 25px;
  text-decoration: none;
  text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn a,
#generic_price_table .generic_content:hover .generic_price_btn a {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}

@media (min-width: 768px) and (max-width: 991px) {
  #generic_price_table .col-md-3 {
    float: left;
    width: 50%;
  }

  #generic_price_table .col-md-4 {
    float: left;
    width: 50%;
  }

  @media (min-width: 1200px) {
  }
  #generic_price_table_home {
    font-family: 'Raleway', sans-serif;
  }

  .text-center h1,
  .text-center h1 a {
    color: #7885cb;
    font-size: 15px;
    font-weight: 300;
    text-decoration: none;
  }
  .demo-pic {
    margin: 0 auto;
  }
  .demo-pic:hover {
    opacity: 0.7;
  }

  #generic_price_table_home ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: table;
  }
  #generic_price_table_home li {
    float: left;
  }
  #generic_price_table_home li + li {
    margin-left: 10px;
    padding-bottom: 10px;
  }
  #generic_price_table_home li a {
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0px;
  }
  #generic_price_table_home .blue {
    background: #3498db;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .emerald {
    background: #2ecc71;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .grey {
    background: #7f8c8d;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .midnight {
    background: #34495e;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .orange {
    background: #e67e22;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .purple {
    background: #9b59b6;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .red {
    background: #e74c3c;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .turquoise {
    background: #1abc9c;
    transition: all 0.3s ease-in-out 0s;
  }

  #generic_price_table_home .blue:hover,
  #generic_price_table_home .emerald:hover,
  #generic_price_table_home .grey:hover,
  #generic_price_table_home .midnight:hover,
  #generic_price_table_home .orange:hover,
  #generic_price_table_home .purple:hover,
  #generic_price_table_home .red:hover,
  #generic_price_table_home .turquoise:hover {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .divider {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
  }
  #generic_price_table_home .divider span {
    width: 100%;
    display: table;
    height: 2px;
    background: #ddd;
    margin: 50px auto;
    line-height: 2px;
  }
  #generic_price_table_home .itemname {
    text-align: center;
    font-size: 15px;
    padding: 50px 0 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    text-decoration: none;
    font-weight: 300;
  }
  #generic_price_table_home .itemnametext {
    text-align: center;
    font-size: 12px;
    padding-top: 5px;
    text-transform: uppercase;
    display: inline-block;
  }
  #generic_price_table_home .footer {
    padding: 40px 0;
  }

  .price-heading {
    text-align: center;
  }
  .price-heading h1 {
    color: #666;
    margin: 0;
    padding: 0 0 50px 0;
  }
  .demo-button {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    outline-color: -moz-use-text-color;
    outline-style: none;
    outline-width: medium;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
  }
  .bottom_btn {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 15px;
    margin: 60px auto 20px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
  }
  .demo-button:hover {
    background-color: #666;
    color: #fff;
    text-decoration: none;
  }
  .bottom_btn:hover {
    background-color: #666;
    color: #fff;
    text-decoration: none;
  }
}

.billing-detail {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #666;
}
.billing-detail .inline {
  display: inline-block;
  vertical-align: middle;
  padding: 0 15px;
}
.billing-detail .swap-btn-bar-admin {
  margin: 0;
}
.billing-detail .inline p {
  margin-bottom: 8px;
}
.generic_feature_list {
  text-align: left;
  padding: 0 35px;
}

.all-tab-area {
  margin-left: -10px;
  margin-right: -10px;
  padding-bottom: 50px;
  padding-top: 30px;
  text-align: center;
}
.all-tab-area a {
  display: inline-block;
  vertical-align: middle;
  padding: 10px 25px;
  color: #565e63 !important;

  box-shadow: 0px 0px 5px 5px #eef5fa;
  font-size: 11px;
  margin: 0 10px 20px;
  text-decoration: none;
  border-radius: 30px;
  text-align: center;
  min-width: inherit;
  width: auto;
}

.all-tab-area a.selected {
  background-color: #204bcd;
  color: #fff !important;
}
/* .main-directory-box {
  text-align: center;
  padding: 50px;
  border-radius: 15px;
  margin: 0 0 20px;
} */
.main-directory-wrapper a {
  color: #222;
}
.main-directory-wrapper a:hover {
  text-decoration: none;
}
/* .directory-profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
} */
/* .main-directory-box h3 {
  font-weight: bold;
  font-size: 15px;
  margin-top: 15px;
}
.main-directory-box h5 {
  font-size: 12px;
  margin-bottom: 15px;
} */
.blue-bg {
  background: #edf1fd;
  box-shadow: 0px 14px 0px #c3cdea;
}

.light-bg {
  background: #edf8fe;
  box-shadow: 0px 14px 0px #d0e6f2;
}
.main-directory-wrapper {
  max-width: 100%;
  margin: 0 auto;
}
.main-directory-wrapper .row {
  justify-content: center;
}
/*chat page*/
/* .chat-app .people-list {
  width: 280px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 7;
}

.chat-app .chat {
  margin-left: 280px;
  border-left: 1px solid #eaeaea;
} */
/* css by divya */
.chat-app .chat-history {
  height: auto;
  overflow-y: auto;
}
.chat-app {
  display: flex;
  flex-direction: inherit;
  justify-content: space-evenly;
}
.chat-app .chat-media-icon-body {
  display: flex;
  justify-content: flex-end;
}
.chat-app .chat-media-icon {
  padding: 20px !important;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border-color: #000;
}
.chat-app .chat-media-icon .fa {
  font-size: 20px;
  color: #000;
}
.chat-app .people-sec-list {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 5px #eee;
  height: 575px;
}
.chat-app .people-sec-list ul {
  overflow-y: auto;
}
.chat-app .people-sec-list .input-group {
  padding: 10px;
  border-bottom: 1px solid #f4f7f6;
}
.chat-app .chat {
  width: 65%;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 5px #eee;
}
/* css by divya */
.people-list {
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 25%;
}

.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px;
  position: relative;
  border-bottom: 1px solid #f4f7f6;
}

.people-list .chat-list li:hover {
  background: #efefef;
  cursor: pointer;
}

.people-list .chat-list li.active {
  background: #efefef;
}

.people-list .chat-list li .name {
  font-size: 12px;
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%;
}

.people-list img {
  float: left;
  border-radius: 50%;
}

.people-list .about {
  float: left;
  padding-left: 8px;
}

.people-list .status {
  color: #999;
  font-size: 11px;
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6;
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
}

.chat .chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 410px;
  overflow-y: scroll;
}

.chat .chat-history ul {
  padding: 0;
  height: 100%;
  width: 100%;
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px;
}
.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 12px;
  border-radius: 8px 0px 8px 8px;
  display: inline-block;
  width: 50%;
}
.chat .chat-history .my-message {
  background: #efefef;
  border-radius: 0 8px 8px 8px;
}

.chat .chat-history .other-message {
  background: #1c3faa;
  color: #fff;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%;
}

.chat .chat-message {
  padding: 20px;
}
.chat .chat-message .chat-social-icon {
  width: 30%;
}
.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 12px;
  vertical-align: middle;
  position: absolute;
  top: 40px;
  left: 52px;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}
.listing-bottom.linksort.minus-margin .clearfix:after {
  display: none;
}
.singal-chat-box {
  width: 700px;
  margin: 50px auto;
  box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%);
  border-radius: 5px;
}
.chat.singal-chat-box .chat-header {
  border: 0;
  padding: 15px 30px;
  background: #0062cc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 4px 7px 0px rgb(69 65 78 / 30%);
}
.chat.singal-chat-box .chat-header img {
  border-radius: 50%;
  width: 85px;
}
.chat.singal-chat-box .chat-header .chat-about {
  float: none;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}
.chat-user-img {
  display: inline-block;
  vertical-align: middle;
}

.chat.singal-chat-box h6 {
  font-size: 15px;
}
.chat-detail-box {
  padding: 30px;
}
.chat-detail-box h2 {
  font-weight: bold;
  margin-bottom: 15px;
}
.chat-app .chat-media-icon:hover {
  background-color: #eee;
}
/* responsive chat start */
@media only screen and (max-width: 767px) {
  .chat-app .chat {
    width: 100%;
  }
  .chat-app .chat-media-icon {
    padding: 15px !important;
  }
  .chat-app .chat-media-icon .fa {
    font-size: 15px;
  }
  .chat .chat-message .chat-social-icon {
    width: 25%;
    padding: 5px;
  }
  .chat-app {
    height: auto;
  }
  .people-list {
    width: 100%;
  }
  .chat-app .chat {
    display: none;
  }
}
/* responsive chat start */
.shorturl-edit-form-input-group label {
  display: inline-block;
  vertical-align: middle;
  width: 90px;
}
.shorturl-edit-form-input-group {
  margin-bottom: 8px;
}
.modal-body .form-control {
  background: #dfe5f3;
  border-color: #bbc3dd;
}
.modal-backdrop.show {
  opacity: 0.1;
}
.btn-primary:focus {
  color: #fff;
}
.btn.btn-primary {
  color: #fff !important;
}
.form-list.w-75 .inline-small {
  vertical-align: top;
}
.btn-admin {
  background: #1c3faa !important;
  padding: 0.65rem 1.4rem;
  color: #fff !important;
  font-size: 11px;
  border-radius: 3px;
  border: 1px solid #1c3faa;
}
span.input-icon.sizespace {
  position: absolute;
  right: 20px;
  top: 20px;
}
.create-user-box .input-solid {
  text-indent: 55px;
}
.create-user-box span {
  position: absolute;
  top: 22px;
  left: 23px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 44%;
  overflow: hidden !important;
  margin: 50px auto 0;
  padding: 0 !important;
  max-height: 300px !important;
}
button.btn-admin.icon-design-admin.inner-popup-delete {
  margin-right: 20px;
}
.form-group.inner-popup.inpt-rel {
  position: relative;
}
.form-group.inner-popup.inpt-rel span.input-icon.sizespace {
  top: 45px;
}
ul#linkSections a {
  display: inline-block;
  list-style-type: none;
  padding: 5px;
  margin-right: 15px;
  cursor: pointer;
}
ul#linkSections {
  margin-top: 15px;
}
.inte-form .swap-btn-bar-admin {
  margin-top: 0;
}
/* .sidebar_minimize .sidebar .sidebar-wrapper .mob-block {
  display: none;
} */
.linksection .active {
  border: 1px solid #193999;
  border-radius: 5px;
}
.customize-box {
  margin-bottom: 10px;
  display: inline-block;

  padding: 5px;
  border-radius: 5px;
}
.customize-box a {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 10px;
  color: #333;
  font-size: 12px;
  text-align: center;
}
.customize-box a.active {
  background: #193999;
  color: #fff;
  border-radius: 3px;
}
.customize-box a:hover {
  text-decoration: none;
}
.customize-box a img {
  max-height: 30px;
}
.customize-box a span {
  display: block;
}
.social-links div {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.modal-content .social-links div .fa {
  position: absolute;
  top: -7px;
  left: 26px;
  background: #fff;
  border: 1px solid #c8c9cb;
  border-radius: 50%;
  font-size: 9px;
  padding: 3px 4px;
}
.social-links.color.Round {
  color: #193999;
}
.social-list-view li {
  list-style-type: none;
  margin-bottom: 15px;
  /* background: #f2f7fb; */
  background: #e7edfd;
  border-radius: 7px;
  padding: 0px 10px;
  border: 2px solid #cfd8f5;
}
.social-list-view li .link-view {
  width: 100%;
  font-size: 11px;
  padding-left: 20px;
}
/* .social-list-view li .icon-view {
  width: 25px;
  margin-right: 10px;
} */
.icon-view img {
  width: 100%;
}
.listurl .personal-social-detail .urllist a {
  white-space: pre-wrap;
}
.listurl .personal-social-detail {
  margin: 0;
  height: auto;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}
.listurl .personal-social-detail h3 {
  margin: 0;
  font-size: 12px;
}

.prsonal-list-icon img {
  max-width: 8px;
}
.listing-bottom button.delbtn {
  font-size: 11px;
  padding-right: 0;
}
.categories_class {
  text-align: center;
  margin: 16px;
  color: red;
}
/**************frontend design css Start**************/

.frontend-navigation {
  background: #ffffff;
  min-height: 57px;
  width: 100%;
  position: fixed;
  z-index: 1001;
  padding: 5px 25px;
}
a.nav-icon {
  display: none;
}
.frontend-navigation ul.nav-list {
  float: right;
  margin: 15px 0 0;
}
.frontend-navigation .logo-box {
  float: left;
}
ul.nav-list li {
  display: inline-block;
  vertical-align: middle;
}
ul.nav-list li a {
  padding: 5px 15px;
  color: #222;
  font-size: 12px;
}
.btn-bar {
  margin-top: 5px;
  margin-left: 10px;
}
.btn-bar a {
  margin-left: 8px;
}
.btn-border.btn-blue {
  border: 1px solid #1572e8;
}
footer {
  padding: 80px 0 40px;
  color: #ebe7e7;
  background: #1c3faa;
}

footer .col-md-8.right-margn {
  padding-left: 60px;
}
ul.inline-sec {
  display: inline-block;
  vertical-align: top;
  list-style-type: none;
  width: 33%;
}

ul.inline-sec li a {
  color: #fff;
  line-height: 280%;
  list-style-type: none;
  text-decoration: none;
}
ul.inline-sec.social li {
  margin-top: 14px;
}
ul.inline-sec.social a {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  color: #3b6fd7;
  font-size: 15px;
  background: #fff;
  text-align: center;
  line-height: 40px;
  margin-right: 7px;
  margin-bottom: 12px;
}
.sub-heading {
  text-transform: uppercase;
  font-size: 12px;
  color: #222222;
  margin-bottom: 10px;
}
.heading-area .sub-heading {
  margin-top: 30px;
  color: #1189d1;
}
.heading-area h1 {
  margin-top: 10px;
}

/* .fron-end-wrapper section {
  padding: 80px 20px 50px;
} */
.feature-detail h1 {
  font-size: 12px;
  color: #222222;
  font-weight: bold;
  margin-top: 65px;
  margin-bottom: 30px;
}
.feature-detail h1 span {
  color: #1189d1;
}
.feature-detail p {
  color: #838383;
  line-height: 26px;
}
.feature-row {
  margin-bottom: 100px;
}
.feature-row .feature-image {
  display: flex;
  justify-content: center;
}
.bg-claim {
  box-shadow: 0 25px 21px 1px rgb(12 14 16 / 15%);
  background: #202940;
  padding: 29px;
  border-radius: 20px;
  max-width: 900px;
  color: #fff;
  margin: 30px auto -24px;
  position: relative;
}
.claim-now-area {
  background: #fff;
  border-radius: 5px;
  margin-top: 20px;
  color: #333;
  padding-left: 14px;
  width: 100%;
}

.claim-now-area .text-input {
  width: 70%;
  padding: 12px 0;
  border: 0;
  display: inline-block;
  font-size: 12px;
}

.login-img img {
  max-width: 70%;
  margin: 0 auto;
}
.signup-left-box {
  padding: 30px 0 50px;
}
.signup-text h1 {
  font-weight: 700;
  font-size: 12px;
}
.signup-text h1 span {
  color: #1189d1;
  display: block;
}
.signup-text p {
  max-width: 60%;
  font-size: 12px;
}

.input-group-prepend.position-absolute {
  right: 18px;
  top: 16px;
}
.input-group-prepend.position-absolute .input-group-text {
  background: none;
  font-size: 12px;
  border: 0;
}
.form-signup a {
  color: #000;
}
.card .card-action {
  border-top: 0 !important;
}
.form-signup .btn-block {
  max-width: 150px;
  margin: 0 auto;
}
\ .change-login {
  margin-top: 50px;
  color: rgb(86, 94, 99);
}
span.text-sign {
  display: block;
}

.change-login .block {
  margin-bottom: 8px;
  display: block;
  font-size: 12px;
}
.change-login p {
  font-size: 11px;
}
.change-login p a {
  color: #1189d1;
  font-weight: bold;
  padding-left: 5px;
}
.box-wrapper {
  text-align: center;
}
.img-section {
  min-height: 250px;
}
.img-section img {
  max-height: 250px;
}
.detail-section {
  max-width: 90%;
  margin: 0 auto;
}
.detail-section h4 {
  font-size: 12px;
  font-weight: bold;
  color: #1c3faa;
}
.detail-section p {
  font-size: 11px;
}

/*-*******/

.testimonial-wrapper {
  margin: auto;
}

.carousel-caption {
  position: initial;
  z-index: 10;
  color: rgba(78, 77, 77, 0.856);
  text-align: center;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: bold;
  line-height: 2rem;
  max-width: 650px;
  margin: 0 auto;
}
#image-caption {
  font-style: normal;
  font-size: 1rem;
  margin-top: 0.5rem;
}
.carousel-caption img {
  width: 6rem;
  border-radius: 5rem;
  margin-top: 2rem;
}

img.designer-img {
  margin-bottom: 62px;
}
/*****27-7-21*****/

.white-box.fron-end-wrapper {
  padding-top: 50px !important;
}
.banner-box {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100 */
  background: rgb(30, 87, 153); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(30, 87, 153, 1) 0%,
    rgba(125, 185, 232, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(30, 87, 153, 1) 0%,
    rgba(125, 185, 232, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(30, 87, 153, 1) 0%,
    rgba(125, 185, 232, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */

  padding: 0;
  color: #fff;
}
.banner-box h2 {
  padding-top: 7rem;
  text-shadow: 0px 2px 1px #5d5b5b;
  font-size: 3rem;
}
/*****27-7-21******/

.accordion-section .panel-default > .panel-heading {
  border: 0;
  background: #f4f4f4;
  padding: 15px 12px;
  border-radius: 5px;
}
.panel-title {
  margin-bottom: 0;
}
.accordion-section .panel-default .panel-title a {
  display: block;
  font-size: 1.1rem;
}
.accordion-section .panel-default .panel-title a:after {
  font-family: 'FontAwesome';
  font-style: normal;
  font-size: 1.3rem;
  /* content: '\f106'; */
  color: #1f7de2;
  float: right;
  margin-top: 0px;
  font-weight: bold;
}
/* .accordion-section .panel-default .panel-title a.collapsed:after {
  content: '\f107';
} */
.accordion-section .panel-default .panel-body {
  font-size: 1.2rem;
}
.panel-group .panel {
  margin-bottom: 20px;
}
.panel-group .panel-collapse {
  padding: 15px 15px 15px 70px;
  position: relative;
  background: #eee;
}
.panel-group .panel-collapse:before {
  height: 60%;
  width: 3px;
  background: #1189d1;
  left: 55px;
  top: 15px;
  position: absolute;
  content: '';
}
.panel-body p {
  font-size: 11px;
}
.accordion-section .panel-default .panel-title a:hover {
  text-decoration: none;
}
/********28-7-21***********/
.heading-box {
  padding: 1.25rem;
}
.heading-box h1 {
  font-size: 2rem;
}
.badge-box p {
  font-size: 11px;
}
.badge-box ul {
  padding-left: 35px;
}
.badge-box ul li {
  font-size: 12px;
  margin-bottom: 8px;
}
p.small.note {
  color: red;
  font-size: 11px;
}

.badge-form li {
  display: inline-block;
  width: 45%;
  margin-right: 25px;
}
.badge-form li.full-width {
  width: 100%;
}
.badge-form li.full-width h3 {
  padding: 20px 10px 0px;
}

.file {
  position: relative;
  overflow: hidden;
}
.file input {
  position: absolute;
  font-size: 15px;
  opacity: 0;
  right: 0;
  top: 0;
}
.block-box {
  display: block;
}
.badge-form li label {
  margin-bottom: 5px;
}
.badge-form li label.block-box {
  margin-top: 15px;
}
.badge-form li.full-width .inline-box {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 45%;
}
.badge-form li.full-width .inline-box .note {
  color: red;
  font-size: 11px;
}
.badge-form li.full-width .inline-box p {
  margin: 0;
}

.badge-form li.full-width .inline-box .fees {
  font-size: 12px;
  font-weight: bold;
}
ul.badge-form {
  width: 65%;
}

.upgrade-box {
  max-width: 900px;
  margin: 0 auto;
}
.bill-box .inline {
  display: inline-block;
  vertical-align: middle;
}
.bill-box .swap-btn-bar-admin.inline {
  margin: 0 20px;
}
.selected-plan {
  margin-top: 20px !important;
}
.selected-plan li:hover {
  /* background: none !important; */
  border: 0 !important;
}
.selected-plan h2 {
  margin-bottom: 0;
  color: #1572e8;
  font-weight: bold;
}
.selected-plan li {
  margin-bottom: 15px;
}
.selected-plan li .inline-box {
  width: 40%;
}
.selected-plan li .plan-price {
  color: #000;
}
.generic_content {
  height: calc(100% - 5px);
}
.bill-box {
  margin-top: 100px;
}

.biolink-profile-box .profile-box {
  margin-top: 0;
}
.profile-social {
  margin-top: 20px;
}
.biolink-profile-box .user-detail {
  margin-top: 15px;
}

.biolink-list .biolink-links {
  display: block;
  background: #f2f7fb;
  padding: 12px;
  border-radius: 10px;
  font-size: 12px;
  color: #000;
}
.biolink-list a.biolink-links:hover {
  text-decoration: none;
  background: #eaeaeb;
}
.bio-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20%;
}
.bio-text {
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
  width: 60%;
}
.bio-icon img {
  max-height: 25px;
}

.biolink-li {
  background: #f2f7fb;
  padding: 12px;
  border-radius: 10px;
}

.bio-box {
  padding: 35px 25px;
  text-align: center;
}
.bio-box .form-group {
  padding: 30px 30px;
  text-align: left;
}
/********28-7-21***********/
/******** Open FAQ 02-Aug-2021 *******/
section.faq-wrapper {
  padding: 0px !important;
}
.faq-row {
  padding: 16px 5px;
  border-bottom: 1px solid #e5e9ed;
}
.faq-listing {
  padding: 0;
  list-style: none;
}

.active.faq-row {
  background: #ebf5fb;
  border-radius: 21px;
  padding: 16px 19px 20px;
}
.faq-content {
  margin: 25px 80px;
  padding-left: 10px;
  border-left: 3px solid #1189d1;
}
.faq-listing li {
  /* display: inline-block; */
  vertical-align: top;
  width: 100%;
  margin-bottom: 0px;
}
.question-box {
  display: flex;
  align-items: center;
}
.faq-row .question-box a {
  color: #222;
  text-decoration: none;
  font-size: 12px;
  padding-left: 39px;
}
button.btnaccordion span {
  padding-top: 4px;
  display: inline-block;
  line-height: 100%;
  vertical-align: middle;
  font-size: 12px;
  color: #222;
}
.question-box a {
  font-size: 12px;
}
.question-box a {
  padding-left: 20px;
}
button.btnaccordion {
  border: none;
}
button.btnaccordion {
  color: #1189d1;
  background: none;
  font-size: 12px;
  display: block;
  width: 100%;
  text-align: left;
}
/******** Close FAQ 02-Aug-2021 *******/
.biolink-list li {
  box-shadow: 0 0 17px rgb(0 6 0 / 15%);
  padding: 15px;
  margin-bottom: 40px !important;
}
.biolink-list li a {
  display: block;
  overflow: hidden;
  width: 100%;
}
.biolink-list.minus-margin embed {
  width: 100%;
  height: 375px;
}
.profile-social .btn {
  margin-bottom: 18px;
}
.profile-social .btn i.fa {
  font-size: 15px;
}
.white-box.fron-end-wrapper.pt-0-biolink {
  padding-top: 0 !important;
  box-shadow: none;
  border-radius: 0;
}
.pt-0-biolink .white-box {
  border-radius: 0px 0px 20px 20px !important;
}
.modal {
  overflow: auto !important;
}

/************2-8-21******************/
.shorter-profile {
  height: auto;
  width: auto;
  border-radius: 0;
}
.shorter-wrapper p {
  margin-bottom: 0;
}

.contact-detail {
  text-align: left;
  padding: 0px 0;
  display: inline-block;
  width: 48%;
  vertical-align: top;
}
.contact-detail h4 {
  font-size: 12px;
  font-weight: bold;
  color: #1189d1;
}
.contact-detail h5 {
  font-weight: bold;
  font-size: 12px;
  padding-bottom: 10px;
}
.contact-list li {
  list-style-type: none;
  margin-top: 10px;
}
.contact-list li .inline-icon {
  display: inline-block;
  width: 30px;
  vertical-align: top;
  font-size: 12px;
  text-align: left;
  padding-top: 5px;
}
.contact-list li .inline-detail {
  display: inline-block;
  vertical-align: top;
  width: 65%;
  font-size: 11px;
  line-height: 150%;
}
.center-img-section {
  padding-bottom: 30px;
}

.about-bottom {
  padding: 20px;
  text-align: left;
  margin-bottom: 50px;
}
.about-bottom .shorter-profile {
  display: inline-block;
  vertical-align: top;
}
.about-bottom .shorter-profile {
  display: inline-block;
  vertical-align: top;
  max-width: 35%;
  overflow: hidden;
  border-radius: 8px;
}
.about-detail {
  display: inline-block;
  vertical-align: top;
  width: 64%;
  padding-left: 25px;
}
.about-detail h3 {
  margin-top: 0;
  font-size: 12px;
}
.about-detail h5 {
  font-size: 12px;
  margin-bottom: 8px;
}
.about-detail .contact-list li {
  margin-top: 5px;
}
.about-detail .contact-list li .inline-icon {
  font-size: 12px;
  padding-top: 6px;
}
.about-detail .inline-sec.social a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
}

/*3-8-21*/
.personal-directory {
  position: relative;
}
.personal-directory .profile-box {
  margin-top: 0;
}
.personal-directory .user-detail {
  margin-top: 0;
}
.personal-directory .socialicon {
  max-width: 700px;
  text-align: left;
  margin: 20px auto;
}
.personal-directory .profile-left h3 {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #1572e8;
}
a.nav-pagi {
  font-size: 12px;
  width: 45px;
  height: 45px;
  border: 1px solid #1572e8;
  border-radius: 50%;
  color: #1572e8;
  text-align: center;
  font-weight: bold;
  display: block;
  line-height: 43px;
}

.pagination-box {
  position: absolute;
  top: 45%;
  width: 100%;
}
a.nav-pagi.prev {
  margin-left: -50px;
}

a.nav-pagi.next {
  margin-right: -50px;
}
.policy-area {
  padding-bottom: 15px;
}
.policy-area h4 {
  margin-bottom: 10px;
}
.policy-area li {
  display: block;
  padding-left: 15px;
  margin-bottom: 12px;
  font-size: 11px;
}
/* by priya */
.cust-icon {
  height: 50px;
  width: 50px;
}

/* */
.center-heading.black-text h2 {
  font-size: 1.6rem;
}
.img-auto img {
  max-height: inherit !important;
}

/************6-8-21*************/
@import url('https://fonts.googleapis.com/css2?family=K2D:wght@100;200;300;400;500;600;700;800&display=swap');

.work-wrapper {
  padding: 50px;
}
.number-box {
  font-family: 'K2D', sans-serif;
  font-weight: 700;
  color: #e9f1f7;
}
span.number-dot {
  padding: 0 8px;
}
.mob {
  display: none !important;
}
.number-box span {
  display: inline-block;
  vertical-align: middle;
}
.text-box {
  padding-left: 35px;
  float: left;
  padding-top: 20px;
  width: 60%;
}
.work-img {
  text-align: center;
}
.work-row .row {
  position: relative;
  padding: 67px 0 !important;
}
.work-row .row:before {
  position: absolute;
  content: '';
  border-bottom: 2px dashed #d3d8dc;
  height: 1px;
  width: 60%;
  bottom: 0;
  left: 18%;
}
.work-row .row::after {
  position: absolute;
  content: '';
  border-left: 2px dashed #d3d8dc;
  height: 53%;
  width: 2px;
  bottom: 0;
  left: 17.5%;
}
.work-row .row.two-box::after {
  right: 21.8%;
  left: inherit;
  height: 100%;
}
.work-row .row:last-child:before {
  display: none;
}
.work-row .row.three-box:last-child:after {
  top: 0;
  height: 42%;
}

.bio-section {
  padding: 50px 0;
  text-align: center;
}
.bio-section h1 {
  font-weight: bold;
  padding-bottom: 30px;
  font-size: 22px !important;
}

.bio-column {
  max-width: 255px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}
.bio-detail {
  position: absolute;
  width: 100%;
  color: #fff;
  bottom: 20px;
}

.bio-column:before {
  background: linear-gradient(
    to bottom,
    rgba(125, 185, 232, 0) 0%,
    rgb(0 0 0 / 28%) 52%,
    rgb(21 23 23) 81%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#2388af',GradientType=0 );
  position: absolute;
  content: '';
  width: 93%;
  height: 100%;
  left: 9px;
}
*/ .bio-column {
  width: 100%;
}
/* .blank-bio {
  background: #1189d1;
  height: 344px;
} */
.bio-detail h1 {
  font-size: 12px;
  padding: 5px;
  line-height: 180%;
  width: 71%;
  margin: 0 auto;
}
.bio-detail h2 {
  font-weight: bold;
  margin-bottom: 0;
}
.bio-detail h3 {
  font-size: 12px;
}

.blog-box {
  position: relative;
  overflow: hidden;
}
.blog-box img {
  width: 100%;
}
.blog-detail {
  position: absolute;
  bottom: 15px;
  color: #fff;
  padding: 0 20px;
}
.blog-detail p,
.blog-text p {
  line-height: 120%;
  margin: 5px 0;
}
.inline-section span {
  display: inline-block;
  vertical-align: middle;
  width: 48%;
  font-size: 12px;
}
.blog-detail .btn {
  margin-bottom: 8px;
}

.heading-blog {
  font-size: 20px;
  padding: 30px 0 25px;
  font-weight: bold;
}
.blog-img {
  display: inline-block;
  vertical-align: middle;
  border-radius: 8px;
  width: 150px;
}
.blog-text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 25px;
  width: 65%;
}
.card-icons img {
  max-width: 50px;
}
.payment-img {
  text-align: center;
}

.sucess-bar {
  color: #090;
  text-align: center;
  padding-bottom: 15px;
}
.sucess-bar .fa {
  font-size: 15px;
}
.sucess-bar h1 {
  font-weight: 100;
  margin-top: 15px;
}

.payment-detail {
  padding: 15px 20px;
  margin-bottom: 0;
}
.payment-detail li {
  display: inline-block;
  width: 48%;
  margin-bottom: 8px;
  font-size: 12px;
  list-style-type: none;
}
.payment-detail li:nth-child(2n) {
  text-align: right;
}
.payment-detail li:nth-child(2n + 1) {
  font-weight: bold;
}
.payment-detail .model-h {
  padding: 0;
}
.model-h {
  padding: 0px 20px;
  font-weight: bold;
}
.cancel-bar {
  color: #f00;
  text-align: center;
  padding-bottom: 15px;
}

.payment-box {
  margin: 0 auto 50px;
  padding: 20px 20px;
  max-width: 600px;
  background: #eee;
}
.sucusses-bar-div div {
  display: inline-block;
  vertical-align: middle;
}

/*********7-8-21*****************/
.inline-icons span {
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px;
}
span.icon-span img {
  max-width: 20px;
}
.other-detail-list li a {
  display: block;
  padding-left: 0px;
  color: #000;
  font-size: 11px;
}
.other-detail-list li {
  list-style-type: none;
  font-size: 11px;
  padding: 14px 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 45%;
}

.other-detail-list li:first-child {
  width: 100%;
  border: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 10px 0;
}
.other-detail-list li {
  list-style-type: none;
  font-size: 11px;
  padding: 10px 0px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 45%;
  margin: 0 2%;
  border-bottom: 1px solid #ddd;
}
.other-detail-list li:before {
  top: 16px;
  color: #000;
  display: none;
}
.other-detail-list h3 {
  margin-bottom: 0;
}

/***********7-8-21-new***************/
a.btn.btn-primary.btn-border {
  color: #1572e8 !important;
}
.monetize-list li {
  display: inline-block;
  vertical-align: middle;
  width: 45%;
  margin: 0px 12px;
  border-bottom: 1px solid #ddd;
  padding: 15px 5px;
}
ul.monetize-list {
  padding: 25px 0;
  max-width: 70%;
}
.monetize-list span {
  padding-right: 5px;
  font-size: 11px;
  color: #979797;
}
.sell-icon {
  font-size: 15px;
  color: #0062cc;
}
.addDestails {
  width: 50%;
  float: left;
}
.active-sell {
  background: #c3cdea;
}
.back-section {
  font-size: 15px;
  line-height: 30px;
}
.back-section span {
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
}
.back-section i {
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
}
.sell-box-detail h3,
.sell-box-detail p,
.sell-box-detail {
  height: auto;
  min-height: auto;
  padding-top: 15px;
  color: #8b8888;
  font-size: 14px !important;
}
/* by priya */

.usd-icon {
  width: 30px;
  background: #0062cc;
  /* display: inline-block; */
  height: 30px;
  border-radius: 50%;
  line-height: 40px;
  color: #fff;
  /* margin-left: 5px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.usd-icon a {
  color: #fff;
}
/* by priya */
/*slider css 11-8-21*/
.section-space {
  padding-top: 20px !important;
  padding-bottom: 15px;
}
.carousel-inner img {
  width: 100%;
  height: 100%;
}

#custCarousel .carousel-indicators {
  position: static;
  margin-top: 20px;
}

#custCarousel .carousel-indicators > li {
  width: 100px;
  position: absolute;
}

#custCarousel .carousel-indicators li img {
  display: block;
  opacity: 0.5;
}

#custCarousel .carousel-indicators li.active img {
  opacity: 1;
}

#custCarousel .carousel-indicators li:hover img {
  opacity: 0.75;
}
.contact-list li .inline-detail {
  margin: 0;
}

.team-detail {
  max-width: 500px;
  margin: 0 auto;
}
.about-detail .inline-sec.social a {
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-size: 11px;
  display: inline-block;
  text-align: center;
  background: #fff;
  border-radius: 5px;
}
.team-detail {
  margin-top: -150px;
  position: relative;
}
#custCarousel .carousel-indicators {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  right: inherit;
  height: 250px;
}

.thumb-one {
  top: 90px;
  left: 0%;
}
.thumb-two {
  left: 12%;
  top: -55px;
}
.thumb-three {
  top: 90px;
  right: 0%;
}
.thumb-four {
  right: 12%;
  top: -55px;
}
.carousel-control-next,
.carousel-control-prev {
  /* background: #1572e8; */
  color: #fff;
  top: inherit;
  opacity: 1;
  width: auto;
  min-width: 85px;
  bottom: inherit;
  left: inherit;
  border-radius: 25px;
  position: relative;
  display: inline-block;
  padding: 8px 8px 5px;
  z-index: 99;
  line-height: 19px;
}
.carosal-nav {
  margin-top: -50px;
  text-align: right;
  max-width: 500px;
}
.list-inline-item a {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  overflow: hidden;
}
.carousel-item img {
  max-height: 600px;
}
a.carousel-control-prev {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

a.carousel-control-next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 12px;
}
.howwork-bottom {
  margin-bottom: 80px;
  padding: 20px;
  margin-top: 55px;
  border: 1px solid #d6dbe1;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inlineview {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.inlineview.righview {
  text-align: center;
}
img.workh {
  position: absolute;
  left: 74%;
  top: -91px;
}
.inlineview.righview span {
  display: block;
  color: #313638;
  font-size: 12px;
  font-weight: 700;
}
a.workbtn {
  background: #1189d1;
  padding: 17px 23px;
  color: #fff !important;
  text-decoration: none;
  margin-top: 27px;
  border-radius: 28px;
  font-size: 12px;
}
.number-value {
  display: inline-block;
  vertical-align: middle;
  color: #000;
  font-size: 20px;
  width: 100px;
  text-align: center;
}
.btn-border.btn-blue.blue {
  color: #1572e8;
}
.social-links-header a {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  color: #fff;
  font-size: 12px;
  background: #1185cb;
  text-align: center;
  line-height: 36px;
  margin-right: 7px;
  border-radius: 5px;
}
.blur-on-lose-focus {
  color: transparent;
  text-shadow: 0 0 5px rgb(0 0 0 / 50%);
}
/* .owl-stage.directory-stage .main-directory-box,
.main-directory-box {
  min-height: 380px;
  border: 1px solid #ddd;
  padding: 20px;
}

.owl-stage.directory-stage .main-directory-box a:hover {
  text-decoration: none;
} */
.directory-learn-btn {
  text-align: center;
  padding: 15px 0;
  display: block;
}
.que-btn {
  text-align: left !important;
  padding-left: 56px;
  vertical-align: middle;
  display: inline-block;
  width: 85%;
}
.swap-btn-bar-admin a.active {
  padding: 8px 8px;
  color: #fff;
  font-weight: normal;
  letter-spacing: 0.5px;
}
.swap-btn-bar-admin a {
  color: #000;
}
button.btn.btn-primary.btn-lg i {
  padding-right: 5px;
}
.listing-bottom button.btn.btn-primary {
  margin-top: 25px;
}
.profile-left button.btn.btn-primary.btn-lg {
  padding: 8px 15px;
  min-width: inherit;
}
.branding-model .swap-box {
  float: right;
}
.branding-model label {
  float: left;
  padding: 0 !important;
}
.branding-model .lockimages {
  float: right;
}
.branding-model .swap-btn-bar-admin {
  margin: 0px 5px;
}
.branding-model .swap-btn-bar-admin a.active {
  /* margin-top: 5px;*/
}
body {
  padding: 0 !important;
}
.error {
  font-size: 12px;
}
/* .urllist {
  max-width: 250px;
} */
td.links-action-col {
  width: 40%;
  padding: 0 !important;
}
td .theam-toggle .lockimages {
  float: right;
  margin-left: 7px;
}
td .theam-toggle .swap-btn-bar-admin {
  margin-top: 0;
  background: #d6d6d6;
}
td .theam-toggle .swap-btn-bar-admin a.active {
  padding: 8px 12px;
}

td .theam-toggle .swap-btn-bar-admin a {
  padding: 12px 8px;
}
.shorter-wrapper.new-shorter-design .directory-profile.shorter-profile {
  height: 55px;
}
/* .shorter-wrapper.new-shorter-design .main-directory-box {
  min-height: 250px;
} */
ul.listing-bottom.minus-margin li {
  display: block;
  /* width: 45%; */
  /* max-width: 600px; */
  margin: 0 auto;
}
.about-detail h5 {
  font-size: 12px !important;
  text-transform: lowercase;
  min-height: auto;
}
.about-detail ul.contact-list p {
  min-height: inherit;
  font-size: 12px !important;
}
.row.feature-row:last-child {
  margin-bottom: 0;
}
.directory-people-slider .owl-nav {
  display: block;
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
}
.directory-people-slider .owl-nav button.owl-prev.owl-prev-directory {
  left: -57px;
  position: absolute;
}

.directory-people-slider .owl-nav button.owl-next.owl-next-directory {
  right: -57px;
  position: absolute;
}
.no-record {
  color: red;
  text-align: center;
}
/*  ****** Dark Mode Toggle******   */
.theams-toggle {
  max-width: 100%;
  margin: 0 auto;
  /* padding: 50px 0 0; */
  /* padding: 20px 0; */
  padding-bottom: 15px;
}

/* Custom by dikshant sir 20Aug21 */
.footer-social-links.author-social-admin.social-media-container a img {
  width: 35px;
  float: left;
}
.footer-social-links.author-social-admin.social-media-container a.social-media-link {
  display: inline-block;
}
.footer-social-links.author-social-admin.social-media-container {
  text-align: center;
}
.form-check label {
  font-weight: 400;
}
/* .user-detail .footer-social-links.author-social-admin.social-media-container a.social-media-link {
  width: 30px;
  padding: 0 5px;
} */
.user-detail
  .footer-social-links.author-social-admin.social-media-container
  a.social-media-link
  img {
  padding: 0;
  width: 100%;
}
td .swap-box .swap-btn-bar-admin {
  margin-top: 0;
}
td .swap-box .lockimages {
  float: right;
  margin-left: 5px;
}
.person-plan-box h4 {
  padding: 5px 15px 15px;
  text-align: center;
  font-size: 12px;
}
.person-plan-box ul {
  width: 70%;
  margin: 0 auto;
}
.person-plan-box ul li {
  list-style-type: none;
  margin-top: 15px;
  font-size: 12px;
}
.person-plan-box ul li .fa-check {
  color: green;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid green;
  text-align: center;
  line-height: 21px;
  margin-right: 15px;
  font-size: 12px;
}
.upgrade-btn-cls {
  margin: 0 auto;
}
.pmt-cls {
  margin: 15px;
}
td .swap-box {
  width: 100px;
}
.customize-row .customize-box {
  display: block;
  /* min-height: 50px; */
}
.customize-body {
  text-align: left;
}

.customize-body {
  text-align: left;
  /* background: #e4e4e4; */
  padding-top: 15px;
  padding-left: 10px;
  margin-top: 30px;
}

.customize-options-container.socialbackground {
  /* display: inline-block; */
  vertical-align: top;
}

.socialstyle {
  display: inline-block;
  width: 70%;
  vertical-align: middle;
}
.socialstyle li.customize-options {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
  margin-bottom: 0;
}
.socialstyle li.customize-options span {
  display: block;
  text-align: center;
  vertical-align: top;
  margin-bottom: 10px;
}
.socialstyle ul {
  margin-bottom: 0;
}
/* .customize-body .customize-options-container {
  display: block;
} */
.customize-options-container.socialbackground .customize-options {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
  padding: 6px 16px;
}

.ReactModal__Overlay {
  z-index: 99999;
}

.modal-heading-wrapper {
  padding: 1rem !important;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background: none !important;
}
.text-block-7 {
  font-size: 12px;
  color: #000;
  font-weight: bold;
}

.model3 .form-group.inner-popup {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
span.label-detail i {
  padding-left: 12px;
}
.user-detail .footer-social-links.author-social-admin.social-media-container {
  text-align: left;
}
.bill-box .inline .lockimages {
  display: inline-block;
  margin-right: 5px;
}
.bill-box .inline .swap-btn-bar-admin {
  margin-top: 0;
  float: none;
  display: inline-block;
  vertical-align: middle;
}
.bill-box .inline {
  min-width: 100px;
}

.theams-toggle .swap-btn-bar-admin,
.theams-toggle .lockimages {
  margin: 00 5px;
  float: none;
  margin-top: 0;
  display: inline-block;
  vertical-align: middle;
}
.theam-label,
.theams-toggle .swap-box {
  display: inline-block;
  vertical-align: middle;
  margin: 00 5px;
}
.theam-label label {
  font-weight: bold;
}
.main-directory-box.light-bg.about-bottom h5 {
  min-height: inherit;
}
.blog-box {
  margin-bottom: 5px;
}
.signup-area h2 {
  font-weight: bold;
}
.signup-area form {
  text-align: left;
}

.signup-area form .card-action.text-center {
  padding: 10px;
}
.small-img {
  max-width: 50%;
  margin: 0 auto;
  display: block;
}

/* ======= eidt section page css  ============ */

.bg-edit-section {
  color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  right: 20px;
  z-index: 9;
}
.dt-changeProfile {
  position: relative;
  z-index: 9;
  text-align: right;
  max-width: 500px;
  margin: 0 0 0 auto;
}
.dt-changeProfile button {
  background-color: #fff;
  border: none;
  padding: 6px 16px;
  border-radius: 20px;
  color: #1c3faa;
  outline: none;
  font-size: 12px;
  font-weight: bold;
}
.dt-changeProfile .theam-label label {
  color: #fff !important;
}

.listing-bottom li {
  display: flex;
}

.edit-preview {
  background-color: inherit !important;
  margin: 10px !important;
}
.edit-preview a {
  padding: 8px 12px !important;
  font-size: 11px !important;
}

/* Open new design by ayush of preview edit page */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.profile-bg {
  background-image: url('../images/profile-bg-01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 170px 20px 20px;
  border-radius: 20px 20px 0px 0px;
  position: relative;
}
.profile-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 1;
}
.bg-edit-section {
  text-align: right;
  top: 0;
  right: 0;
}
.bg-edit-section a {
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 15px !important;
  border-radius: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 9;
  display: inline-block;
  text-decoration: none;
}
.profileImages {
  width: 150px;
  border-radius: 50%;
  border: 2px solid #000;
  background: #fff;
  margin-bottom: 10px;
  border: 3px solid #1572e8;
  position: relative;
}
.profileImages img {
  border-radius: 50%;
}
.img-responsive {
  width: 16%;
}
.img-responsive-pro {
  width: 100%;
}
.user-info ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.profileHeading {
  display: flex;
  align-items: center;
}
.dt-changeProfile h4 {
  color: #fff;
  margin-bottom: 12px;
}
.dt-changeProfile {
  position: relative;
  z-index: 9;
}
.profiletext {
  color: #fff;
  margin-left: 15px;
  margin-bottom: 15px;
}
.profiletext a {
  display: inline-block;
  color: #fff;
  text-decoration: underline;
  margin-bottom: 10px;
}
.profiletext h3 {
  font-size: 12px;
}

.social_media_icon li,
.payment_icon li {
  display: inline-block;
}
.social_media_icon li a img {
  width: 25px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.payment-icon {
  padding: 15px 0px;
  text-align: left;
}
.payment-icon a {
  display: inline-block;
  margin-right: 15px;
}
.payment-icon a img {
  max-height: 30px;
}
.edit-preview {
  padding: 10px;
  max-width: 200px;
  margin: 5px 0 0 auto;
  border-radius: 40px;
}
.edit-section {
  background-color: #0378d5;
  padding: 5px 25px;
  border-radius: 20px;
  color: #fff;
}
.detailsCard {
  margin-top: 250px;
}
.divider {
  margin-top: 50px;
}
.table tr th {
  width: 30%;
}

/* close new design by ayush of preview edit page */
.tb-resk .table {
  margin-bottom: 0px !important;
}

.tb-resk .table tr th {
  width: 20%;
}

.tb-resk .table tr th,
.tb-resk .table tr td {
  padding: 0px !important;
}
.detailsCard .card,
.card-bg .card {
  box-shadow: 2px 6px 15px 6px rgb(69 65 78 / 10%);
}

.detailsCard .card .card-header,
.card-bg .card .card-header {
  background: rgba(0, 0, 0, 0.03);
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0px;
}
.detailsCard .card-header,
.card-bg .card .card-header {
  border-bottom: 0px !important;
}
.user-info ul li {
  margin-bottom: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.pd-80 {
  padding-top: 60px;
}
.aded-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.aded-btn .usd-icon {
  margin-left: 5px;
}
.aded-btn span {
  padding-right: 10px;
}
.text-heading {
  margin-left: 15px;
}
.hed-title .label-box {
  width: inherit;
}
.hed-title .label-detail {
  width: 150px !important;
}
.addBtnhead .customize-options {
  display: inherit;
  margin: 0px;
}
.fa-clone:hover {
  color: lightgreen;
  cursor: pointer;
}
.footer-sec ul.inline-sec.social li {
  display: flex;
  flex-wrap: wrap;
}
.footer-sec ul.inline-sec.social li a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.footer-sec .fa {
  font-size: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar_minimize .logo-header .nav-toggle {
  right: -50px !important;
}
@media (min-width: 768px) {
  .UserProfileInfo {
    position: absolute;
    top: -120px;
    left: 30px;
    z-index: 9;
  }
}
@media (max-width: 767px) {
  .footer-sec ul.inline-sec.social li {
    justify-content: center;
  }
  .modal-content .social-links div .fa {
    font-size: 6px;
    top: -6px;
    left: 25px;
  }
  .upgrade-area .col-md-6 .generic_feature_list {
    padding: 0 20px;
  }
  .upgrade-area .selected-plan li h2 {
    width: 50%;
    margin: 0;
  }
  .upgrade-area .lockimages {
    margin: 0 140px;
  }
  .upgrade-area .text-right {
    padding-right: 20px;
  }
  .upgrade-area .selected-plan li .plan-price {
    width: 50%;
    margin: 0;
  }
  .howwork-bottom {
    flex-direction: column;
  }
  a.workbtn {
    padding: 10px 23px;
  }
  .center-heading.black-text h1 {
    font-size: 14px !important;
  }
  span.label-box .fa {
    width: 15px;
  }
  .edit-preview {
    margin: 5px !important;
  }
  .modal-dialog {
    padding-top: 60px;
  }
  .edit-preview a {
    padding: 3px 4px !important;
    font-size: 11px !important;
  }
  .hed-title .label-box {
    width: 17px;
  }
  .hed-title .label-detail i {
    padding-left: 5px;
  }
  .hed-title {
    padding-left: 0 !important;
  }
  .header-bg-01 {
    width: 100%;
    display: flex;
  }
  .full-dash .card {
    margin-bottom: 30px;
  }
  .hed-title .label-detail {
    display: flex;
    align-items: center;
  }
  .detailsCard {
    margin-top: 0px;
  }
  .tb-resk .table tr th {
    width: 30%;
  }
  .text-heading {
    margin-left: 95px;
    margin-top: 17px;
  }
}
/* Open Upgrade design by priya 01Sep21 */
.active-plan {
  background: #cad1e7 !important;
  border-radius: 5px;
  color: #ffffff !important;
  padding: 10px;
  box-shadow: 0px 2px 4px 0px rgb(235 235 235);
}
.active-plan:hover {
  background: #b2d8fd;
  border-radius: 5px;
  color: #ffffff !important;
  padding: 10px;
}
/* Close Upgrade design by priya 01Sep21 */

/* darkmode css */

.dark-mode {
  background-color: black;
  color: white;
}
body {
}
.bg-img-section {
}
.pd-80 {
  padding: 80px;
}
.pd1-080 {
  padding: 80px;
}
.header-bg-01 {
  padding: 0px 15px 15px 0px;
}
.actBtn {
  background-color: #0062cc;
  border: 1px solid #0062cc;
  color: #fff;
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  outline: medium none;
  padding: 10px 25px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
}
.social-link-section li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-sect i {
  color: #0062cc;
}
.md-header-area {
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.md-usernm {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #1572e8;
}

.md-user-info .preview-list li {
  line-height: 35px;
}
/* .pd1-090 {
  padding: 90px;
} */
.tst-profileHead .btn-border.btn-info {
  background-color: #fff !important;
}

.link_with .links-action-btn {
  padding: 5px !important;
}

.link-Shorttable tbody td a {
  color: #000;
}
.usr-accont li {
  display: block;
}
.acnt-head .big-title {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  font-size: 12px !important;
}
p,
label,
a {
  font-size: 12px !important;
}
@media (max-width: 400px) {
  .responsiveTable td .tdBefore {
    font-size: 10px;
  }
}
.btn {
  border-radius: 25px !important;
  padding: 10px 25px !important;
}
.db-tble {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
/*---------------------------------- add some css new by divya-------------------------- */
.signup-text.text-center.mb-5 {
  padding-top: 20px;
}
.claim-now-area.float-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}
.page-inner {
  margin: 2% 8%;
}
.edit-preview {
  /* background-color: #d6d6d6 !important; */
  background-color: #e7edfd !important;
  padding: 3px 5px;
}
.sidebar {
  background-color: #1c3faa;
}
.sidebar .nav .nav-item a i,
.sidebar .nav .nav-item a p {
  color: #fff;
}
.sidebar .refresh-btn {
  background-color: #fff !important;
}
.refresh-btn .mob-block,
.refresh-btn i {
  color: #1c3faa;
  font-weight: bold;
}
.sidebar .nav-item {
  padding-right: 0;
  margin-left: 15px;
  border-radius: 20px 0px 0px 20px;
}
.sidebar .nav-item.active {
  background-color: #fff;
  margin-left: 15px;
  border-radius: 20px 0px 0px 20px;
}
/* .sidebar .nav-item:hover{
  background-color: #fff;
  margin-left: 15px;
  border-radius: 20px 0px 0px 20px;
} */
.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a .hover-img {
  visibility: visible;
}
.sidebar.sidebar-style-2 .nav .nav-item a:hover {
  background-color: #fff;

  border-radius: 20px 0px 0px 20px;
}
.sidebar.sidebar-style-2 .nav .nav-item a:hover .hover-img {
  visibility: visible;
}
.sidebar.sidebar-style-2 .nav .nav-item {
  padding: 0;
}
.sidebar .nav-item a:hover p,
.sidebar .nav-item a:hover i {
  color: #1c3faa;
  background: none;
}

.scroll-wrapper {
  margin-top: 20px;
}
/* .page-inner
{
max-width: 100%;
margin: 0;
} */
.main-header {
  background-color: #1c3faa;
}
.navbar-header,
.logo-header {
  background-color: #fff;
}
.main-panel .content {
  background-color: #fff;
}

.userProfile {
  top: -88px !important;
}
.btn-white {
  background-color: #fff !important;
  color: #1c3faa !important;
}
.ct-details-col {
  display: flex;
}
.dashboard-list {
  display: flex !important;
  align-items: center;
}
.dashboard-list input {
  font-size: 10px;
}
.personal-social-detail {
  height: auto;
}
.notif-center {
  padding: 12px 12px;
  font-size: 12px;
}
.notif-center .notif-list {
  padding: 5px;
  color: #000;
  border-bottom: 1px solid #ececec;
}
.lockimages {
  width: 17px;
}
.hed-title {
  display: flex;
  align-items: center;
  padding-left: 2px;
}
/* navbar-profile */
.profile-center .notif-list {
  align-items: center;
}
.profile-center .hidden-div {
  visibility: hidden;
}
.profile-center .notif-list .notif-content {
  padding: 0;
  text-align: center;
}
.profile-center .notif-list .notif-icon {
  margin: 0;
}
.profile-center .notif-btn {
  margin-top: 0;
  display: flex;
  align-items: center;
  width: 100px;
}
.profile-center .notif-btn i {
  color: red;
}
.theam-label,
.theams-toggle .swap-box {
  margin: 0;
}
.profile-center .notif-list .notif-icon {
  width: 30px;
  height: 30px;
}
.navbar-expand-lg .navbar-nav .submenu .dropdown-menu {
  right: 70px;
}
.navbar-expand-lg .navbar-nav .dropdown .dropdown-menu {
  right: 0px;
}
.profile-center .btn:hover {
  color: green !important;
}
.profile-center .btn {
  background-color: unset !important;
  color: #0a5b8b !important;
  padding: 0 !important;
}
.profile-center .btn span {
  display: none;
}
.profile-center .notif-list .notif-icon {
  background: unset !important;
  color: #000 !important;
}
.eFPnub {
  height: auto;
}
button.delbtn {
  padding: 0;
}
.main-header,
.main-header .navbar-header {
  background: #1c3faa !important;
}
.logo-header {
  background-color: #1c3faa !important;
}
.logo-header .nav-toggle {
  right: -15px;
}
.logo-header .logo {
  background: #fff;
  border-radius: 10px 10px 0 10px;
  height: 84%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-header .logo .navbar-brand {
  width: 85%;
  margin: 0 !important;
  padding: 0 !important;
}
.navbar .navbar-nav .nav-item .nav-link {
  width: 25px;
  padding: 2px;
}
.nav-toggle img {
  max-width: 65%;
}
.avatar-sm img {
  width: 80%;
  height: 80%;
}
/* notification start */
.notification-row {
  border-bottom: 1px solid #e0dfdf;
  padding: 5px 0px;
}
.notification-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification-col h4,
.notification-col p {
  margin: 0;
  width: 95px;
  overflow: hidden;
}
.notification-text p {
  margin: 0;
  color: #6c757d;
}
.scroll-fix-header {
  visibility: hidden;
}
.navbar .navbar-nav .nav-item {
  width: 20%;
}
.hover-img {
  position: absolute;
  visibility: hidden;
}
.white-box.dashboard {
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 0px 10px 10px #eee;
}
.dashboard .donation-wrapper {
  text-align: center;
}
/* notification-end */
/*---------------------------------- add some css new by divya--------------------------- */
/* responsive start */
@media only screen and (max-width: 1024px) {
  .header-bg-01 {
    padding: 0px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .logo-header .logo {
    display: none;
  }
  .profile-header {
    height: 185px;
    padding-bottom: 10px;
  }
  .main-header .navbar-header {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }
  .main-panel .content {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .dashboard
    .user-detail
    .footer-social-links.author-social-admin.social-media-container
    a.social-media-link {
    width: 35px;
    margin: 5px 5px;
  }
  .logo-header .logo {
    display: flex;
    position: absolute;
    left: -65px;
    width: 100px;
    height: 65%;
  }
  .claim-now-area a.btn {
    width: 60%;
    margin: 10px;
  }
  .userProfile {
    top: -110px !important;
  }
  .page-inner {
    margin: 0 auto;
  }
  .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a .hover-img,
  ul.nav.nav-primary.menubar img,
  .hover-img {
    width: 30px !important;
  }
  .scroll-fix-header {
    justify-content: space-around;
    visibility: hidden;
  }
  .scroll-fix-header .scroll-toggle {
    display: flex;
    padding-top: 0;
  }
  .scroll-fix-header .scroll-fix-btn {
    display: flex;
    align-items: center;
  }
  .scroll-fix-header .scroll-fix-btn button {
    padding: 5px;
    border-radius: 30px;
    font-size: 11px;
    border: none;
    margin: 0 10px;
    background-color: #fff;
  }
  .scroll-fix-header .scroll-fix-btn button i {
    display: none;
  }
  .scroll-fix-header .scroll-toggle .clearfix {
    display: flex;
    align-items: center;
  }
  .scroll-fix-header .scroll-toggle .swap-btn-bar-admin {
    margin: 0px 7px;
  }

  .scroll-fix-header .scroll-toggle .theam-label label {
    color: #fff !important;
  }
  .other-detail-list li {
    list-style-type: none;
    font-size: 11px;
    padding: 13px 0px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 45%;
    margin: 0 2%;
    border-bottom: 1px solid #ddd;
  }
  .icon-head-nav {
    width: 25px;
  }
  .prsonal-list-icon {
    display: flex !important;
  }
  .prsonal-list-icon .btn-sm {
    padding: 0 !important;
  }
  .social-list-view li .link-view {
    width: 70%;
    font-size: 11px;
    padding: 10px 5px;
  }
  .social-list-view li .icon-view {
    margin-right: 0;
  }
  .social-list-view li {
    padding: 0;
    margin-bottom: 7px;
  }
  .swap-btn-bar-admin a {
    padding: 8px 8px;
  }
  .aded-btn .usd-icon {
    width: 25px;
    height: 25px;
    margin-left: 3px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    width: 25px;
    padding: 2px;
  }
  .profile-header {
    align-items: flex-end;
  }
  .prsonal-list-icon {
    padding-left: 5px;
  }
  .listurl .personal-social-detail .urllist {
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-nav .submenu .dropdown-menu {
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .dropdown .dropdown-menu {
    left: auto;
  }
  .navbar-header .navbar-nav .dropdown-menu {
    width: 245px;
    left: -225px;
  }
  .full-dash {
    padding: 0;
  }
  .text-right {
    padding-right: 0;
  }
  .main-panel .content {
    margin-top: 0;
  }
  .main-header .navbar-header {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }
  .full-dash .card-title {
    padding: 0;
  }
  span.label-detail {
    margin-top: 0 !important;
  }
  .bg-edit-section {
    bottom: 40px;
    top: 0px;
    right: 35px;
  }
  .sidebar .nav-item.active,
  .sidebar .nav-item {
    padding: 0 !important;
    margin: 0px 0px 0px 5px;
  }
  .sidebar .nav-item.active a,
  .sidebar .nav-item a {
    display: flex !important;
    padding: 4px 10px !important;
  }
  .sidebar .nav-item.active i,
  .sidebar .nav-item i {
    width: 10px !important;
    font-size: 14px !important;
    margin-right: 12px !important;
  }
  .sidebar .nav-item.active a p,
  .sidebar .nav-item a p {
    text-align: left !important;
    font-size: 11px !important;
  }
  .sidebar .refresh-btn {
    padding: 5px 16px !important;
  }
  .social-link-section li {
    justify-content: space-between;
  }
  .header-bg-01 {
    margin-right: auto;
  }
  /* css for scroll view  */
  .scroll-fix-header {
    justify-content: space-around;
  }
  .scroll-toggle {
    flex-direction: initial;
    padding-top: 0;
  }
  .scroll-fix-btn {
    position: initial;
  }

  .dt-changeProfile button {
    font-size: 11px;
    padding: 6px 6px;
    background-color: #1c3faa;
    color: #fff;
    font-weight: normal;
  }
  .dt-changeProfile {
    margin: 0;
    display: flex;
    width: auto;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 0;
  }
  /* .dt-changeProfile {
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  } */
  .scroll-header {
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 17px 0px;
    visibility: visible;
    /* animation: fadeIn 1s; */
  }
  /* @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  } */
  .theams-toggle {
    margin: 0;
    padding-top: 15px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;
  }
  .urllist {
    width: 190px;
    padding-left: 10px;
  }
  .personal-social-detail span,
  .personal-social-detail {
    padding-left: 10px;
  }
  .listurl {
    display: flex;
    height: auto;
  }
  .user-detail {
    margin: 0;
  }
  .user-detail-list {
    display: flex;
    line-height: 30px;
  }
  .listurl-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .userProfile {
    display: flex !important;
    position: absolute;
    left: 0;
    align-items: flex-start;
  }
  .userProfile .text-heading {
    color: #fff;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  button.delbtn {
    padding: 0;
  }
  .bg-edit-section .btn {
    padding: 4px 8px !important;
    font-size: 10px !important;
    right: -25px;
  }
  .profile-header {
    /* margin-top: 30px; */
    align-items: flex-end;
    padding-bottom: 20px;
    height: 185px;
  }
  .user-detail-list span.label-box {
    width: 36% !important;
  }
  .order2 {
    order: 2;
  }
  .user-detail .footer-social-links.author-social-admin.social-media-container a.social-media-link {
    width: 35px;
  }
  .dt-changeProfile .aded-btn {
    justify-content: flex-end;
    position: absolute;
    bottom: -52px;
    left: -149px;
  }
  /* .dt-changeProfile .aded-btn {
    justify-content: flex-end;
    position: absolute;
    bottom: -59px;
    left: -10px;
  } */
  .aded-btn span {
    display: none;
  }
  .btn .btn-label {
    display: none;
  }
  .btn {
    padding: 8px 20px !important;
  }
  .donation-box a {
    margin: 0;
  }
  .profile-box {
    width: 85px !important;
    height: 110px !important;
    position: absolute;
    top: 2px;
  }
  .urllist a {
    white-space: pre-wrap !important;
  }
  .file-btn {
    bottom: 31px;
  }
  .file-btn img {
    max-width: 25px;
  }
  .theams-toggle label {
    font-size: 9px !important;
  }
}

/* responsive end */
/* preview-page start */
.scrol-class {
  overflow-y: scroll !important;
}
.links-and-video-tab {
  border-radius: 20px;
  box-shadow: 0px 0px 10px 10px #eee;
  padding: 30px 10px;
}
.preview-links-section .links-list {
  background-color: #eee;
  padding: 10px;
  list-style-type: none;
  text-decoration: none;
}
.preview-links,
.preview-videos {
  width: 50%;
}
.videos-list {
  width: 350px;
  height: 220px;
}
.preview-links .nav-link:hover,
.preview-videos .nav-link:hover {
  background: #1572e8 !important;
  color: #fff;
}
.preview-video-section {
  display: flex;
  justify-content: space-between;
}
.preview-videos-list {
  display: flex;
}
.preview-videos-list .videos-list iframe {
  width: 100%;
  height: 100%;
}
.frontend-navigation {
  background: #1c3faa;
}
.frontend-navigation .logo-box {
  border-radius: 10px 10px 0 10px;
  width: 10%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frontend-navigation .float-right .btn {
  background: #fff !important;
  color: #000 !important;
  border: none;
}
.frontend-navigation .nav-clk-cls {
  color: #fff;
}
.preview-page .card {
  box-shadow: none;
}
.preview-page .swap-btn-bar-admin {
  margin: 10px;
}
.preview-page .social-media-link {
  width: 35px !important;
  display: flex;
}
.preview-page .biolink-list li {
  box-shadow: none;
  margin-bottom: 0 !important;
}
.hed-title .label-detail {
  margin-top: 0;
}
.embedo-item embed {
  width: 100%;
}
.ytp-cued-thumbnail-overlay-image {
  background-size: contain;
}
.preview-page
  .user-detail
  .footer-social-links.author-social-admin.social-media-container
  a.social-media-link
  img,
.dashboard
  .user-detail
  .footer-social-links.author-social-admin.social-media-container
  a.social-media-link
  img {
  width: 100%;
  margin: 0;
}
.preview-page .minimal.Square .btn-admin,
.preview-page .minimal.OneClick .btn-admin,
.preview-page .social-links.color.OneClick a.btn-admin,
.preview-page .social-links.color.Square a.btn-admin,
.dashboard .minimal.Square .btn-admin,
.dashboard .minimal.OneClick .btn-admin,
.dashboard .social-links.color.OneClick a.btn-admin,
.dashboard .social-links.color.Square a.btn-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
/* responsive start */
@media only screen and (max-width: 768px) {
  .frontend-navigation .nav-clk-cls {
    color: #000;
  }
  .frontend-navigation {
    display: flex;
    display: flex;
    justify-content: space-between;
  }
  .frontend-navigation .logo-box {
    order: 2;
    width: 20%;
  }
  .frontend-navigation .logo-box a img {
    margin: 0;
  }
  .frontend-navigation .float-right {
    order: 3;
  }
  .frontend-navigation .nav-icon {
    order: 1;
  }
  .frontend-navigation ul.nav-list {
    text-align: left !important;
    top: 45px !important;
    box-shadow: 1px 4px 4px 2px #dee2e6;
    border-radius: 0px 0px 30px 0px;
  }
  .frontend-navigation .nav-list li {
    border-bottom: 1px solid #bfbfbf;
  }
  .preview-page .page-inner {
    margin: 0;
  }
  .preview-page .text-center {
    padding: 0px;
  }
  .preview-page .userProfile {
    left: 43px;
    align-items: center;
  }
  .preview-page .profile-left {
    display: flex;
    justify-content: center;
  }
  .preview-page .userProfile .text-heading {
    margin-left: 45px !important;
  }
  .preview-page .userProfile .text-heading h3,
  .preview-page .userProfile .text-heading {
    padding-top: 5px;
    margin: 0;
    align-items: flex-start;
  }
  .preview-page .hed-title {
    width: 100%;
  }
  .preview-page .hed-title .label-box {
    width: 20px;
  }
  /* ul.preview-list li {
   display: flex;
   line-height: 20px;
 } */
}
@media only screen and (max-width: 767px) {
  .preview-videos-list .videos-list {
    width: 310px;
    height: auto;
    margin: 10px 0px;
  }
  .preview-video-section {
    flex-direction: column;
  }
  .sidebar .nav,
  .sidebar[data-background-color='white'] .nav {
    margin-top: 42px;
  }
  .preview-videos-section .preview-videos-list {
    flex-direction: column;
  }
  .preview-videos-section .preview-videos-list a {
    width: 100%;
  }
  .btn-bar {
    margin: 0 0 !important;
  }
  .frontend-navigation {
    align-items: center;
  }
  .biolink-list.minus-margin embed {
    width: 100%;
    height: 200px;
  }
  .embedo-item embed {
    width: 100%;
    height: auto;
  }
  .preview-page ul.preview-list li {
    display: flex;
  }
  .preview-page ul.preview-list li .label-box {
    width: 36%;
  }
  .preview-page .swap-btn-bar-admin a {
    padding: 3px 4px !important;
    font-size: 11px !important;
  }
  .frontend-navigation .logo-box {
    order: 2;
    width: 25%;
    height: 100%;
  }
}
/* ------------------------------------------------------------------------- */
/* biolink page start */
.preview-page .card-body .white-box {
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 0px 10px 10px #eee;
}
.pt-0-biolink .tst-profileHead .btn-border.btn-info {
  color: #000 !important;
  font-weight: bold;
}
.pt-0-biolink .userProfile {
  top: -88px !important;
}
.biolink-list {
  box-shadow: 0 0 17px rgb(0 6 0 / 15%);
  border-radius: 20px;
}
.pt-0-biolink .biolink-list li {
  box-shadow: none;
  margin-bottom: 0 !important;
}
.pt-0-biolink .full-height {
  box-shadow: none;
}
.pt-0-biolink .user-detail {
  margin: 0;
  padding: 20px;
  box-shadow: 0px 9px 10px 2px #eee;
  border-radius: 0px 0px 20px 20px;
}
.pt-0-biolink .profile-social {
  position: absolute;
  top: -130px;
  right: -830px;
}
.hide-referral {
  visibility: hidden;
}
@media only screen and (max-width: 1440px) {
  .pt-0-biolink .profile-social {
    right: -470px;
  }
}
@media only screen and (max-width: 1280px) {
  .pt-0-biolink .profile-social {
    right: -400px;
  }
}
@media only screen and (max-width: 767px) {
  .bio-section h1 {
    font-size: 18px !important;
  }
  .pt-0-biolink .profile-social {
    top: -85px;
    right: 25px;
  }
  .icon-options-vertical {
    height: 35px;
  }
  .pt-0-biolink .hed-title .label-detail {
    justify-content: flex-start;
  }
  .pt-0-biolink .userProfile {
    display: flex !important;
    top: -75px !important;
    position: absolute;
    left: 7px;
    align-items: flex-start;
  }
  .pt-0-biolink .profile-social .btn i.fa {
    font-size: 16px;
  }
  .pt-0-biolink .userProfile .text-heading {
    margin-top: 30px;
  }
  .pt-0-biolink ul.preview-list {
    padding: 0;
  }
  .pt-0-biolink .text-center {
    padding: 0px;
  }
  .pt-0-biolink ul.preview-list li {
    display: flex;
  }
  .pt-0-biolink span.label-box {
    width: 55%;
  }
  .biolink-social {
    padding: 0;
  }
  .pt-0-biolink .hed-title .label-box {
    width: 36%;
  }
  ul.nav.nav-primary.menubar img,
  .hover-img {
    height: 15px !important;
  }
}
/* -------------------------------------------------social-media-form page start------------------------------------*/
.social-customize-body {
  display: flex;
  align-items: center;
  color: #193999 !important;
  font-weight: bold;
}
.social-links.minimal.Square,
.social-links.minimal.Round,
.social-links.color.Square,
.social-links.color.Round {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.full-dash .social-links.minimal.Square,
.full-dash .social-links.minimal.Round,
.full-dash .social-links.color.Square,
.full-dash .social-links.color.Round {
  margin: 0;
  padding: 5px;
}
.social-media-form-section h6 {
  color: #193999;
  font-weight: bold;
}
.social-media-form-section p {
  margin: 0;
}
.modal-body .minimal.Square .btn-admin,
.modal-body .minimal.OneClick .btn-admin,
.modal-body .social-links.color.OneClick a.btn-admin,
.modal-body .social-links.color.Square a.btn-admin {
  padding: 0;
  justify-content: flex-start;
}
.full-dash .minimal.Square .btn-admin img,
.full-dash .minimal.OneClick .btn-admin img,
.full-dash .social-links.color.OneClick a.btn-admin img,
.full-dash .social-links.color.Square a.btn-admin img {
  padding: 0;
}
.minimal.Square .btn-admin img,
.minimal.OneClick .btn-admin img,
.social-links.color.OneClick a.btn-admin img,
.social-links.color.Square a.btn-admin img {
  max-width: 100%;
  padding: 5px;
}
.social-media-form-section .round-shape.active,
.social-media-form-section .square-shape.active,
.social-media-form-section .follow-shape.active {
  border-radius: 14px;
}
.social-customize-body .social-form-toggle {
  margin: 0 12px;
}
.social-media-form-section .style-section {
  padding: 20px 0px 0 0;
}
.social-media-form-section .style-section .links {
  color: #000;
  padding: 8px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin: 0px 4px;
  cursor: pointer;
}
.social-media-form-section .style-section .links.active {
  color: #fff;
}
.social-media-form-section .shape-area {
  padding: 30px 0px 0 0;
}
.social-media-form-section .shape-area-body {
  display: flex;
  cursor: pointer;
}
.social-media-form-section .round-shape,
.social-media-form-section .square-shape,
.social-media-form-section .follow-shape {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-right: 20px;
  box-shadow: 0px 0px 5px 5px #eee;
  border-radius: 20px;
  color: #000;
  width: 85px;
  height: 85px;
}
.social-media-form-section .shape-area.minimal .round-shape:hover,
.social-media-form-section .shape-area.minimal .square-shape:hover,
.social-media-form-section .shape-area.minimal .follow-shape:hover {
  background-color: #193999;
  color: #fff !important;
}
.social-media-form-section .shape-area.minimal .round-shape:hover .fa,
.social-media-form-section .shape-area.minimal .square-shape:hover .fa,
.social-media-form-section .shape-area.minimal .follow-shape:hover .follow-social {
  background-color: #fff;
  color: #000 !important;
}
.social-media-form-section .follow-shape .follow-img {
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  padding: 2px 7px;
  border-radius: 5px;
}
.social-media-form-section .follow-shape .follow-img p {
  margin: 0;
  padding-left: 5px;
}
.blue-close span {
  border: 3px solid #193999;
  border-radius: 50%;
  padding: 3px 9px;
  color: #193999;
}
.social-media-form-section .shape-area.color .round-shape,
.social-media-form-section .shape-area.color .square-shape {
  background: #ffffff;
  color: #1c3faa;
  font-weight: bold;
  color: #0062cc;
}
.social-media-form-section .shape-area.color .round-shape:hover,
.social-media-form-section .shape-area.color .square-shape:hover,
.social-media-form-section .shape-area.color .round-shape.active,
.social-media-form-section .shape-area.color .square-shape.active {
  background: #193999;
  color: #fff;
}
.social-media-form-section .shape-area.color .round-shape:hover .fa,
.social-media-form-section .shape-area.color .square-shape:hover .fa,
.social-media-form-section .shape-area.color .round-shape.active .fa,
.social-media-form-section .shape-area.color .square-shape.active .fa {
  background-color: #fff;
  color: #0062cc;
}
.social-media-form-section .shape-area.color .square-shape:hover p {
  color: #fff;
}
.social-media-form-section .shape-area.color .round-shape .fa,
.social-media-form-section .shape-area.color .square-shape .fa {
  background-color: #0062cc;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-social .social-links {
  margin-top: 14px;
}
.add-social .social-links .btn-admin {
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-dash .social-links .btn-admin {
  margin-bottom: 0;
}
.add-social .social-links .btn-admin img {
  margin: 0;
  padding: 0;
}
.add-social .social-links.minimal.Square .btn-admin {
  box-shadow: none;
}
.new-customize {
  background: #e7edfd;
  padding: 5px;
  width: 190px;
  border-radius: 8px;
  display: flex;
  box-shadow: 5px 6px 10px 0px #d3caca;
}
.new-customize .customize-options {
  padding: 8px 0px;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}
.new-customize .customize-options.active {
  background: #1c3faa;
}
.contact-customize .customize-body {
  margin: 0;
}
.form-list {
  margin-top: 0 !important;
}
@media only screen and (max-width: 767px) {
  .new-customize {
    flex-wrap: nowrap;
  }
}
/*dikshant css*/
a.round-shape .fa {
  background: rgb(237 237 237);
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 6px;
  color: #000;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
}
a.square-shape .fa {
  background: rgb(237 237 237);
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 6px;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
}

span.follow-social {
  text-align: center;
  padding: 6px;
  color: #000;
  border: 1px solid #d4d2d2;
  border-radius: 5px;
}

ul.nav.nav-primary.menubar img,
.hover-img {
  width: 31px;
  padding-right: 13px;
  height: 16px;
  line-height: 180%;
}
.social-links.color.Round .btn-admin:hover {
  border: 1px solid #e7e7e7;
}
.social-links.color.Round .btn-admin {
  background: #fff !important;
  border: 1px solid #cdcdcd;
  padding: 5px;
}
.full-dash .social-links.color.Round .btn-admin,
.full-dash .social-links.color.Square .btn-admin {
  margin: 0;
}
.social-links.color.Square .btn-admin {
  background: #fff !important;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
}
.social-links.color.OneClick a.btn-admin {
  background: inherit !important;
  border: 1px solid #cdcdcd;
}
.social-links.minimal.Round a.btn-admin {
  padding: 5px;
  /* box-shadow: 0px 0px 5px 2px #a0b2c5; */
}
.social-links.minimal.Square a.btn-admin {
  border-radius: 5px;
  /* box-shadow: 0px 0px 5px 2px #a0b2c5; */
}
.social-links.minimal.OneClick a.btn-admin {
  border-radius: inherit;
  color: #ccc;
}
.blue-close span {
  padding: 1px 5px;
}
.blue-close {
  font-size: 17px;
}
.customize-box .minimal-style {
  background: #e7edfd;
  padding: 5px;
  width: 190px;
  border-radius: 8px;
  display: flex;
  box-shadow: 5px 6px 10px 0px #d3caca;
}
.add-media-btn {
  background: #1b3b9a;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.add-media-btn a {
  color: #fff !important;
  font-weight: bold;
}
.modal-header {
  align-items: center;
}
@media only screen and (max-width: 767px) {
  /* .social-media-form-section .style-section,
  .social-media-form-section .shape-area {
    padding: 10px 0px;
  } */
  .add-media-btn {
    margin: 0 10px;
  }
  .modal-content .social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .blue-close {
    font-size: 11px;
  }
}

/* -------------------------------------------------social-media-form page end------------------------------------*/
/* @media only screen and (max-width: 444px) {
  .shorturl-edit-form {
    white-space: nowrap;
    width: 279px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .shorturl-edit-form-input-group label {
    width: 68px;
  }
  .prsonal-list-icon {
    float: right;
    display: initial;
    align-items: center;
  }
  .listing-bottom button.delbtn {
    font-size: 16px;
    padding-right: 0;
    margin-left: 7px;
  }
  .prsonal-list-icon img {
    margin-left: 18px;
    max-width: 8px;
  }
}
@media only screen and (max-width: 396px) {
  .prsonal-list-icon {
    float: right;
    display: initial;
    align-items: center;
    position: absolute;
    right: 0px;
  }
}
@media only screen and (max-width: 350px) {
  .shorturl-edit-form-input-group label {
    display: inline-block;
    vertical-align: middle;
    width: 44px;
  }
  .shorturl-edit-form {
    width: 251px;
  }
  .listurl {
    padding: 8px 4px !important;
  }

  .listing-bottom button.delbtn {
    font-size: 16px;
    padding-left: 7px;
    margin-top: 8px;
  }
  .prsonal-list-icon {
    position: absolute;
    right: 0px;
    display: initial;
  }
}
@media only screen and (max-width: 346px) {
  .listing-bottom button.delbtn {
    font-size: 13px;
    padding-left: 3px;
    margin-top: 8px;
  }
} */

/*------------------------------------------- link-shortner start---------------------------------------------- */
.link-shortner .table {
  display: flex;
  flex-direction: column;
}
.link-shortner .table thead {
  border-radius: 10px;
}
.link-shortner .table thead tr {
  display: flex;
  justify-content: space-between;
}
.link-shortner .table tbody tr td {
  padding: 10px !important;
}
.link-shortner .table tbody tr {
  border-bottom: 1px solid #d3dfff;
  /* background: #edf2ff; */
  box-shadow: 0px 0px 2px 2px #e6edff;
}
.link-shortner .table tr th {
  font-size: 12px;
}
.link-shortner .table tr th,
.link-shortner .table tr {
  display: flex;
  align-items: center;
  padding: 5px 0 !important;
  border: none;
}
.link-shortner .table tr th:nth-child(4) {
  padding-left: 5px !important;
}
.link-shortner .table tr th:nth-child(1),
.link-shortner .table tr td:nth-child(1) {
  width: 10%;
  padding-left: 20px !important;
}
.link-shortner .table tr th:nth-child(2),
.link-shortner .table tr td:nth-child(2) {
  width: 30%;
}
.link-shortner .table tr th:nth-child(3),
.link-shortner .table tr td:nth-child(3) {
  width: 30%;
}
.link-shortner .table tr th:nth-child(4),
.link-shortner .table tr td:nth-child(4) {
  width: 18%;
}
.link-shortner .table tr th:nth-child(5),
.link-shortner .table tr td:nth-child(5) {
  width: 10%;
  display: flex;
  align-items: center;
}
.link-shortner .table td,
.link-shortner .table th {
  border: none;
}
.link-shortner .links-action-col {
  display: flex;
}
.link-shortner .swap-box {
  display: flex;
}
.link-shortner .table thead {
  background-color: #1c3faa;
  color: #fff;
}
.link-shortner .card-box {
  box-shadow: none;
}
.link-shortner .links-action-btn .fa-trash {
  color: red;
}
.link-shortner .urllist {
  padding-left: 0;
}
.link-shortner .urllist a,
.link-shortner .table tbody tr td a {
  color: #000;
  text-decoration: none;
}
.link-shortner .swap-btn-bar-admin {
  margin-left: 10px;
  background: #edf2ff;
}
@media only screen and (max-width: 1440px) {
  .link-shortner .table tr td:nth-child(5) {
    width: 13%;
  }
  .main-directory-box:after {
    width: 150px !important;
  }
  .main-directory-box {
    height: 85% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .link-shortner .table tr th:nth-child(5),
  .link-shortner .table tr td:nth-child(5) {
    width: 20%;
  }
  .main-directory-box:after {
    width: 90px !important;
    bottom: 18px !important;
  }
  .link-shortner .table tr th:nth-child(1),
  .link-shortner .table tr td:nth-child(1) {
    padding-left: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .main-directory-box {
    height: 80% !important;
  }
  .link-shortner .table tbody tr td span {
    padding-left: 10px !important;
  }
  .link-shortner .table tr th:nth-child(4) {
    padding-left: 12px !important;
  }
  .main-directory-box.green-bg:after {
    bottom: 27px;
  }
  .bg-main-directory .main-directory-box:after {
    bottom: 25px !important;
  }
  .design-artist-section .main-directory-box:after {
    bottom: 8px !important;
  }
  .main-directory-box:after {
    width: 230px !important;
    bottom: 10px !important;
  }
  .link-shortner .table tbody tr td,
  .link-shortner .table tr th,
  .link-shortner .table tr {
    padding: 5px 10px !important;
  }
  .link-shortner .table {
    display: block;
    flex-direction: column;
  }
  .link-shortner .urllist {
    padding-left: 10px;
  }
  .link-shortner .table tr td:nth-child(1) {
    width: 10%;
  }
  .link-shortner .table tr td:nth-child(4) {
    width: 100%;
  }
  .link-shortner .table tr td:nth-child(5) {
    width: 100%;
  }
  .link-shortner .table tr th:nth-child(1) {
    width: 4%;
  }
  .link-shortner .table tr th:nth-child(3) {
    padding: 0 !important;
  }
  .link-shortner .table tr th:nth-child(4) {
    width: 21%;
    justify-content: flex-start;
    display: flex;
  }
  .link-shortner .table tr th:nth-child(5) {
    width: 14%;
  }
  .link-shortner .links-action-col {
    display: flex !important;
  }
  .link-shortner .swap-box {
    justify-content: flex-end;
  }
  .link-shortner .table-responsive {
    border: 1px solid #e3e0e0 !important;
    border-radius: 15px 15px 0 0;
  }
  .link-shortner .table-responsive .table {
    padding: 0;
  }
  .link-shortner .table-responsive::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  /* Track */
  .link-shortner .table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .link-shortner .table-responsive::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .link-shortner .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
/*------------------------------------------- link-shortner end---------------------------------------------- */

/*------------------------------------------- main-directory end---------------------------------------------- */
.MuiOutlinedInput-notchedOutline {
  border-radius: 35px !important;
}
.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-endAdornment {
  right: 10px;
  z-index: 1112;
}
.directory-profile {
  display: flex;
  align-items: center;
}
.main-directory-box p,
.directory-author h5 {
  color: #868687 !important;
}
.directory-people-slider h5,
.main-directory-box h5 {
  font-size: 11px !important;
}
.directory-people-slider h3,
.main-directory-box h3 {
  text-transform: uppercase;
  font-weight: bold;
}
.directory-img {
  width: 35%;
}
.directory-author {
  width: 65%;
}
.main-directory-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  background: #fff;
  box-shadow: 0px 0px 5px 3px #eee;
  border: 1px solid #eee;
  margin: 20px 0px;
  border-radius: 10px;
}
.main-directory-box:after {
  width: 215px;
  height: 10px;
  position: absolute;
  content: ' ';
  background-color: #fff;
  bottom: 10px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 3px 5px 5px #eee;
}
.card.main-directory,
.card.main-people-directory {
  box-shadow: none;
}
.card.main-directory {
  background-color: #eef5fa;
}
.directory-profile .directory-author h5 {
  font-size: 11px !important;
  min-height: auto;
  margin: 0 !important;
}
.main-directory-box a p {
  font-size: 11px !important;
  margin: 10px 0px;
  border-bottom: 1px solid #ddd;
}
.bg-main-directory {
  background-color: #eef5fa;
}
.bg-main-directory .form-list {
  position: relative;
}
.bg-main-directory .form-list .fa-search {
  font-size: 20px;
  position: absolute;
  top: 18px;
  left: 20px;
  color: grey;
}
.main-directory-box .btn {
  padding: 5px 25px !important;
}
/* .owl-item{
  width: 200px !important;
} */
.directory-people-slider .owl-nav button.owl-next.owl-next-directory,
.directory-people-slider .owl-nav button.owl-prev.owl-prev-directory {
  font-weight: normal !important;
  color: #000 !important;
  border: none !important;
  font-size: 40px !important;
  top: -20px !important;
}
@media only screen and (max-width: 767px) {
  a.nav-icon {
    margin-top: 0 !important;
  }
  .bg-main-directory .form-list .fa-search {
    top: 13px;
  }
  .directory-people-slider .owl-nav button.owl-next.owl-next-directory {
    right: -32px;
  }
  .directory-people-slider .owl-nav button.owl-prev.owl-prev-directory {
    left: -32px;
  }
  .main-directory-box:after {
    width: 240px;
  }
  .card.main-directory {
    background-color: transparent;
  }
  .bg-main-directory {
    padding-top: 50px !important;
  }
  .bg-main-directory .card {
    padding: 15px !important;
  }
  .all-tab-area a {
    margin: 4px 4px;
    padding: 4px 10px !important;
    font-size: 10px !important;
    box-shadow: 0px 0px 3px 3px #eef5fa;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 4px !important;
  }
  .directory-img,
  .directory-author {
    width: 40%;
  }
  .directory-profile {
    justify-content: space-evenly;
  }
  .main-directory-box .btn {
    padding: 5px 50px !important;
  }
}
/*------------------------------------------- main-directory end---------------------------------------------- */

/*------------------------------------------- my-directory start---------------------------------------------- */
.my-directory .my-directory-list {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 30px 0px 0px 0px;
  flex-direction: column;
  list-style-type: none;
}
.my-directory .user-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
}
.my-directory .my-dir-border {
  border-right: 2px solid #ebe6e6;
}
.my-directory .clearfix:after {
  display: none;
}
.my-directory .prsonal-list-icon {
  padding: 10px 40px;
}
.my-directory .preview-wrapper {
  margin: 0;
  box-shadow: 0px 0px 10px 10px #eee;
  border-radius: 20px;
}
.my-directory ul.preview-list {
  margin-top: 0 !important;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding-left: 60px !important;
}
@media only screen and (max-width: 767px) {
  .sidebar .nav,
  .sidebar[data-background-color='white'] .nav {
    margin-top: 75px;
  }
  .scroll-wrapper {
    margin-top: 0;
  }
  .logo-header button {
    width: 25px !important;
  }
  .bg-main-directory .form-list .fa-search {
    top: 10px;
  }
  .bg-main-directory .form-list .fa-search {
    font-size: 15px;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    padding: 4.5px 50px !important;
  }
  .my-directory .listurl .personal-social-detail {
    width: 80%;
  }
  .my-directory .prsonal-list-icon {
    padding: 0px 0px;
  }
  .my-dir-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid #ebe6e6;
  }
  .my-dir-body .profile-box {
    width: 50%;
    position: relative;
  }
  .my-dir-body .my-directory-list {
    width: 50%;
    padding: 10px 0px;
    margin: 0;
  }
  .my-directory .my-dir-border {
    border: none;
  }
  .my-directory .user-detail {
    padding: 0px 20px;
    width: 100% !important;
  }
  .my-directory .card.full-height {
    box-shadow: 0px 0px 10px 10px #eee;
  }
  .my-directory .md-usernm {
    text-align: center;
  }
  .my-directory .preview-list li {
    display: flex;
  }
  .my-directory .preview-list li .label-box {
    width: 45%;
  }
}
/*------------------------------------------- my-directory end---------------------------------------------- */
/*------------------------------------------- account section start---------------------------------------------- */
.account-section .delete-box {
  display: flex;
  justify-content: flex-end;
}
.account-section .delete-box a {
  color: #fff;
}
.account-section .delete-box:hover a {
  color: #fff;
}
.account-section .create-user-box .input-solid {
  text-indent: 2px;
  border-radius: 20px;
  border-color: #1c3faa;
  padding: 12px 75px;
}
.account-section .fa-check-circle-o {
  color: #31ce36;
}
.account-section .profile-img-box {
  display: flex;
  width: 5%;
}
.account-section .listurl .personal-social-detail {
  width: 50%;
}
.account-section .biolink-status {
  display: flex;
  align-items: center;
  width: 28%;
  justify-content: space-between;
}
.account-section .btn-admin {
  padding: 5px 20px;
}
.account-section .prsonal-list-icon.blue-text {
  margin: 0;
  width: 33%;
  justify-content: flex-end;
}
.account-section .md-usernm {
  text-align: center;
}
.account-section .setting-listing .listurl {
  padding: 3px 10px;
}
.account-section .bio-section {
  padding: 10px 0;
}
@media only screen and (max-width: 767px) {
  .account-section .create-user-box .input-solid {
    text-indent: 10px;
  }
  .account-section .profile-img-box {
    width: 30%;
  }
  .account-section ul.listing-bottom.setting-listing .prsonal-list-icon.blue-text {
    display: flex;
    justify-content: center;
  }
  .account-section .biolink-status {
    justify-content: center;
  }
  .account-section .btn-admin {
    padding: 3px 5px;
  }
}
/*------------------------------------------- account section end---------------------------------------------- */
/*------------------------------------------- billing section start---------------------------------------------- */
.billing-section {
  padding: 0 !important;
}
.upgrade-area .generic_content {
  margin-top: 0 !important;
  height: 100% !important;
}
.upgrade-box .generic_head_price {
  height: 350px !important;
}
.pricing-container {
  padding-bottom: 100px;
}
.billing-section .generic_feature_list .fa-check {
  color: #31ce36;
}
.billing-section .generic_feature_list .fa-times {
  color: red;
}
.billing-section .generic_content.clearfix {
  margin-top: 130px;
  border-radius: 20px;
}
.billing-section .generic_content.clearfix.Highlighted {
  margin: 0;
}
.billing-section .generic_content {
  height: calc(100% - 100px);
}
.billing-section .generic_price_btn a {
  background: #1c3faa;
  color: #fff;
}
.billing-section .generic_head_price {
  background-image: url(../images/billing-bg.png);
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
}
.billing-section .generic_content.clearfix.Highlighted .generic_head_price {
  background-image: url(../images/premium-plan.png);
}
.billing-section .generic_content.clearfix.ht-cls .generic_head_price {
  background-image: url(../images/Savings-Plan.png);
}
.billing-section .generic_price_tag span {
  color: #fff !important;
  font-weight: bold;
}
.billing-section .generic_head_content span {
  font-weight: bold;
  color: #48abf7 !important;
}
.billing-section .table-head-bg-primary thead {
  border: none !important;
}
.billing-section .table-head-bg-primary thead th:nth-child(1) {
  border-radius: 10px 0px 0px 10px;
}
.billing-section .table-head-bg-primary thead th:last-child {
  border-radius: 0px 10px 10px 0px;
}

@media only screen and (max-width: 1440px) {
  .billing-section .generic_head_price {
    height: 300px;
    background-position: center;
  }
}
@media only screen and (max-width: 767px) {
  /* .pricing-area .generic_head_price{
      height: 290px !important;
    } */
  .pricing-row {
    margin: 0;
  }
  .billing-section .generic_head_price {
    height: 266px;
  }
  .billing-section .generic_content.clearfix {
    margin: auto;
  }
  .billing-section .card.full-height {
    box-shadow: none;
  }
  .billing-section .generic_price_btn a {
    background: #1c3faa;
    color: #fff;
  }
  .billing-section .generic_head_price {
    background-image: url(../images/billing-bg.png);
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .billing-section .generic_content.clearfix.Highlighted .generic_head_price {
    background-image: url(../images/premium-plan.png);
  }
  .billing-section .generic_content.clearfix.ht-cls .generic_head_price {
    background-image: url(../images/Savings-Plan.png);
  }
  .billing-section .generic_price_tag span {
    color: #fff !important;
    font-weight: bold;
  }
  .billing-section .generic_head_content span {
    font-weight: bold;
    color: #48abf7 !important;
  }
  .billing-section .table-head-bg-primary thead {
    border: none !important;
  }
  .billing-section .table-head-bg-primary thead th:nth-child(1) {
    border-radius: 10px 0px 0px 10px;
  }
  .billing-section .table-head-bg-primary thead th:last-child {
    border-radius: 0px 10px 10px 0px;
  }

  @media only screen and (max-width: 767px) {
    /* .pricing-area .generic_head_price{
      height: 290px !important;
    } */
    .upgrade-box .col-md-6 {
      margin-top: 30px;
    }
    .billing-section .generic_head_price {
      height: 266px;
    }
    .billing-section .generic_content.clearfix {
      margin: auto;
    }
    .billing-section .card.full-height {
      box-shadow: none;
    }
    .billing-section .generic_content {
      height: calc(100% - 5px);
    }
    .billing-section .bil-space {
      margin: 10px 0px;
    }
  }
  @media only screen and (max-width: 375px) {
    .billing-section .generic_head_price {
      height: 275px;
    }
  }
}
/*------------------------------------------- billing section end---------------------------------------------- */
/*------------------------------------------- verification section start---------------------------------------------- */
.verification-section .heading-box h1 {
  font-size: 20px !important;
  color: #1c3faa;
}
.verification-section .heading-box {
  padding-bottom: 0;
}
.verification-section .heading-box h2 {
  font-size: 17px !important;
}
.verification-section .badge-box ul {
  color: #959595;
}
.social-links.minimal.Square div.btn-admin {
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px #a0b2c5;
}
.verification-section .pending {
  background-image: url(../images/pending-verif.png);
  background-repeat: no-repeat;
  background-position: center;
}
.verification-section .verified {
  background-image: url(../images/verified-verif.png);
  background-repeat: no-repeat;
  background-position: center;
}
.verification-section .rejected {
  background-image: url(../images/rejected-verif.png);
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .verification-section .pending,
  .verification-section .verified,
  .verification-section .rejected {
    background-size: 200px;
  }

  .verification-section .heading-box h2 {
    font-size: 14px !important;
  }
  .verification-section .heading-box h1 {
    font-size: 16px !important;
  }
}
/*------------------------------------------- verification section end---------------------------------------------- */
/*------------------------------------------- biolink-directry start---------------------------------------------- */
.biolink-inner {
  margin: 2% 0 !important;
}
.biolink-directry .socialicon .cust-icon img {
  width: 50%;
}
.biolink-directry {
  padding: 5px !important;
  margin: 0 !important;
  align-items: center !important;
  box-shadow: 0px 0px 5px 5px #eee;
  border-radius: 20px;
}
.biolink-directry .pagination-box {
  top: 42%;
}
.biolink-directry a.nav-pagi {
  border: none;
}
.biolink-directry a.nav-pagi i {
  font-size: 30px;
}
.biolink-directry-col .clearfix::after {
  display: none !important;
}
.biolink-directry .socialicon span {
  background: none;
}
.biolink-directry .user-detail {
  border-left: 1px solid #cbcaca;
  padding: 20px;
}
.biolink-directry ul.preview-list {
  margin-top: 0 !important;
}
.biolink-directry .user-info ul li {
  margin-bottom: 0 !important;
}
.biolink-directry .socialicon span {
  margin-right: 0;
}
.account-model .inner-popup1.text-center {
  display: flex !important;
  justify-content: flex-end !important;
}
.account-model .inner-popup1.text-center button {
  border-radius: 50px;
}
.account-model .inner-popup1.text-center .inner-popup-delete {
  background-color: #f25961 !important;
  border: #f25961 !important;
  margin-right: 10px;
}
.ReactModal__Content.ReactModal__Content--after-open {
  width: 25% !important;
  max-height: 225px !important;
  transition: transform 0.3s ease-out;
}
.account-model input {
  background: #dfe5f3;
  border-color: #bbc3dd;
}
.account-model .modal-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.personal-directory.row.biolink-directry .biolink-first h2 {
  text-align: center !important;
}
@media only screen and (max-width: 767px) {
  .biolink-directry span.label-box {
    width: 60%;
  }
  .biolink-directry .socialicon .cust-icon {
    height: auto;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    width: 80% !important;
    max-height: 210px !important;
  }
  .account-model .inner-popup1.text-center {
    padding: 0px 10px;
  }
  .biolink-directry-col .listurl a {
    width: 50%;
  }
  .biolink-directry .biolink-first {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .biolink-directry .biolink-first .profile-box {
    position: initial;
    height: auto !important;
    margin-top: 30px;
  }
  .biolink-directry .preview-list {
    margin-top: 0 !important;
  }
  .biolink-directry .user-detail {
    /* border-top: 1px solid #cbcaca; */
    border-left: none;
    width: 100% !important;
  }
  .biolink-directry .preview-list li {
    display: flex;
  }
  .biolink-directry .socialicon .cust-icon {
    margin-bottom: 0;
    padding: 0;
    width: 10%;
  }
  .biolink-directry a.nav-pagi.prev {
    margin-left: -40px;
  }
  .biolink-directry a.nav-pagi.next {
    margin-right: -40px;
  }
}
/*------------------------------------------- biolink-directry end---------------------------------------------- */
/*------------------------------------------- Home page start---------------------------------------------- */
.home-banner .home-right-col {
  background-image: url('../images/bg.png');
  height: 405px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.home-banner .home-right-col p {
  font-size: 18px !important;
}
.how-work-left .home-left-title {
  font-size: 36px !important;
}
.home-banner .how-work-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home-banner .how-work-left .how-work-left-body {
  width: 500px;
}
.home-banner .how-work-left .btn-border.btn-blue.blue {
  color: #1c3faa;
  font-weight: bold;
  border: 1px solid #1c3faa;
}
.home-page-social .fa {
  color: #fff;
  font-size: 30px;
}
.home-page-social {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-page-social p {
  font-weight: bold;
  color: #1c3faa;
  margin-left: 16px;
  font-size: 15px !important;
}
.home-banner-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner-link a {
  background-color: #1c3faa;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0px 10px;
  text-decoration: none;
}
.home-how-it-work {
  box-shadow: none;
  background-color: #f8f9fa;
  border-radius: 0px 0px 0px 0px;
  padding: 50px 0px;
}
.home-how-it-work .sub-heading {
  color: #000 !important;
  font-weight: bold;
}
.home-how-it-work .box-wrapper {
  padding: 20px;
  border: 2px solid #1c3faa;
  border-radius: 20px;
}
.home-how-it-work .home-img img {
  width: 100%;
}
.home-how-it-work .box-wrapper .detail-section {
  min-height: 110px;
}
.home-how-it-work .box-wrapper .home-img {
  min-height: 210px;
}
.home-user-review {
  background-color: #f8f9fa;
  padding: 50px 0px;
}
.user-review-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-review-sec-img {
  width: 200px;
  height: 300px;
  background-color: #d6edff;
  margin-top: -248px;
  margin-left: 135px;
  color: #d6edff;
}
.home-user-review .carousel-caption {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.home-user-review .carousel-caption p {
  text-align: left;
}
.design-artist-section {
  background-color: #f8f9fa;
}
.design-artist-section .designer-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.design-artist-section .designer-text h2 {
  font-size: 18px !important;
  color: #56585e;
  font-weight: bold;
}
.design-artist-section .designer-img,
.design-artist-section .artist-img {
  display: flex;
  justify-content: center;
}
.design-artist-section .design-artist-row {
  border-top: 1px solid #e7e7e7;
  padding: 40px 0px;
}
.design-artist-section .bg-main-directory {
  background-color: #f8f9fa;
}
.design-artist-section .bg-main-directory .home-main-directory {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 10px #eee;
}
.design-artist-section .bg-main-directory .directory-people {
  padding: 50px 0px;
}
.how-work-left-body {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.home-bg-color {
  background-color: #f8f9fa;
}
.home-user-review .carousel-caption {
  padding-left: 30px;
}
@media only screen and (max-width: 768px) {
  .how-work-left-body {
    width: 100%;
    align-items: center;
  }
  .home-banner .home-right-col {
    height: 460px;
  }
  .how-work-left .home-left-title {
    font-size: 20px !important;
  }
  .home-how-it-work .box-wrapper .home-img {
    min-height: 130px;
  }
  .user-review-sec-img {
    margin-left: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .home-banner-row {
    margin: 0;
  }
  .home-bg-color .row {
    margin: 0;
  }
  .home-user-review .center-heading {
    padding-bottom: 55px;
  }
  .home-banner-link a {
    width: 35px;
    height: 35px;
  }
  .home-page-social .fa {
    font-size: 14px;
  }
  .home-how-it-work .box-wrapper .home-img {
    min-height: 140px;
  }
  .home-banner {
    background-color: #fff;
    margin-bottom: 0 !important;
  }
  .how-work-left-body,
  .home-banner .how-work-left {
    align-items: center;
  }
  .how-work-left-body {
    width: 100% !important;
  }
  .how-work-left .home-left-title {
    font-size: 19px !important;
    padding: 0px 50px;
  }
  .home-page-social {
    margin-top: 20px !important;
  }
  .home-banner .home-right-col {
    background: none;
    background-color: #cbe9fb;
    padding-left: 0;
  }
  .home-banner .home-right-col p {
    font-size: 12px !important;
  }
  .home-how-it-work .box-wrapper {
    margin: 10px 40px;
  }
  .design-artist-section .designer-img,
  .design-artist-section .artist-img {
    padding: 35px 20px;
  }
  .home-order {
    order: 2;
  }
  .home-main-directory .MuiAutocomplete-root {
    margin: 0px 10px;
  }
  .home-main-directory .all-tab-area {
    margin: 5px;
  }
}
/*------------------------------------------- Home page end---------------------------------------------- */
/*------------------------------------------- About-us page start---------------------------------------------- */
.about-us-section {
  background-color: #fff;
}
.about-us-section .feature-detail h1 {
  color: #1189d1;
  font-size: 20px !important;
  margin: 10px 0px;
}
.about-us-section .feature-detail h1 span {
  text-decoration: underline;
  color: #000;
}
.about-us-section .feature-row {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 0;
  padding: 50px 0px;
}
.about-us-section .card {
  box-shadow: none;
  margin-bottom: 0;
}
.about-us-section .order-md-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-section .feature-detail {
  margin: 10px 0px;
}
.about-us-section .about-bottom .shorter-profile {
  max-width: 20%;
  border-radius: 50%;
}
.about-us-section .inline-sec.social {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-section .inline-sec.social a {
  color: #6e6c6c;
  margin: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #6e6c6c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
}
.about-us-section .about-detail h3 {
  font-size: 14px !important;
}
.about-us-section .light-bg {
  background: #fff;
  box-shadow: 0px 0px 5px 5px #eee;
  border-radius: 10px;
}
.about-us-section .shorter-wrapper p {
  color: #838383;
}
@media only screen and (max-width: 767px) {
  .about-us-section .feature-detail {
    margin-top: 40px;
  }
  .about-us-section .feature-detail h1 {
    font-size: 14px !important;
  }
}
/*------------------------------------------- About-us page end---------------------------------------------- */
/*------------------------------------------- Our Team page start---------------------------------------------- */
.team-us-section .card {
  box-shadow: none;
}
.team-us-section .light-bg {
  background: #fff;
  box-shadow: 0px 0px 5px 5px #eee;
  border-radius: 10px;
}
.team-us-section .inline-sec.social {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-us-section .inline-sec.social a {
  color: #6e6c6c;
  margin: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #6e6c6c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
}
.team-us-section .about-detail h3 {
  padding-top: 15px;
  font-size: 20px !important;
}
.team-us-section .about-detail {
  padding-left: 0;
}
.team-us-section .about-bottom .shorter-profile {
  max-width: 20%;
}
.team-us-section .about-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.team-us-section .team-detail {
  max-width: 550px;
}
.team-us-section .about-bottom .shorter-profile img {
  border-radius: 10px;
}
@media only screen and (max-width: 1024px) {
  .team-us-section .about-bottom .shorter-profile {
    max-width: 60%;
  }
}
@media only screen and (max-width: 767px) {
  .team-us-section .about-bottom .shorter-profile {
    max-width: 50%;
    display: flex;
    justify-content: center;
  }
  .team-us-section .about-body {
    flex-direction: column;
  }
  .team-us-section .light-bg {
    margin: 10px;
  }
}
/*------------------------------------------- Our Team page end---------------------------------------------- */

/*how it work by dikshant sir css*/
.howitwork {
  margin-top: 42px;
  text-align: center;
}
.howitwork .black-text.heading-area img {
  width: 50%;
}
.v-middle {
  vertical-align: middle;
  margin-top: 39px;
  margin-bottom: 82px;
  border-bottom: 1px solid #ccc;
}
.col-md-6.v-middle p {
  font-size: 18px !important;
}
/* .heading-class h1 {
  font-size: 28px !important;
} */
.how-title-sec h1 {
  font-size: 22px !important;
}
.col-md-6.v-middle img {
  padding-bottom: 27px;
}
.heading-class span {
  color: #1c3faa;
}
.heading-class h1:before {
  position: absolute;
  content: '';
  border-bottom: 3px solid #575f6d;
  top: 35px;
  width: 100px;
}
.heading-class {
  position: relative;
}
.heading-area h1 {
  font-size: 28px !important;
}
.heading-area h1 {
  font-size: 28px !important;
}
.howwork-get-link span {
  font-size: 18px !important;
}
.inlineview.righview span {
  border: 1px solid #1e41aa;
  border-radius: 12px;
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.signup-section {
  background-image: url('../images/register.png');
  background-repeat: no-repeat;
  height: 676px;
  margin-top: 3px;
}
.signup-section .contact-us-last-btn {
  text-align: center;
}
.new-sec p {
  color: #fff;
  font-size: 17px !important;
  text-align: left;
  width: 560px;
}
.new-sec h1 {
  font-size: 26px !important;
  color: #fff;
  font-weight: 600;
}

.login-img {
  width: 82%;
  margin: 7% auto;
}

.new-sec {
  width: 95%;
  margin: 15% auto;
}
.form-signup h1 {
  text-align: center;
  font-size: 27px !important;
}
.signup-section .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
/*contact us page css*/
.box-wrapper.contact-sec .contact-list {
  color: #fff;
}
.box-wrapper.contact-sec .contact-detail {
  width: auto;
}
.box-wrapper.contact-sec {
  text-align: inherit;
  margin-top: 64px;
  margin-left: 40px;
}

.box-wrapper.contact-sec .contact-list li .inline-detail {
  font-size: 11px !important;
  width: 230px;
  color: #c8cfe7;
}
.box-wrapper.contact-sec .contact-detail h4 {
  color: #fff;
  font-size: 22px !important;
}
.contact-sec .img-section.center-img-section {
  width: 75%;
  margin: 0 auto;
}

.box-wrapper.contact-sec h1 {
  color: #fff;
  font-size: 27px !important;
  width: 483px;
  margin-bottom: 35px;
}
.box-wrapper.contact-sec .contact-detail h5 {
  color: #fff;
  font-size: 14px !important;
  font-weight: 400;
}
.cform {
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.cform .form-list {
  width: 60%;
}
::placeholder {
  color: red;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}
.signup-section .signup-contact-details {
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial-sec-img.v-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.howwork-link-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .howitwork {
    margin-top: 30px;
  }
  .home-banner .home-right-col {
    height: 390px;
  }
  .box-wrapper.contact-sec .contact-list li .inline-detail {
    width: 185px;
  }
  .cform {
    justify-content: flex-end;
  }
  .cform .form-list {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .signup-section {
    background-image: none;
    background-color: #2b51b4;
  }
  .signup-section .cform .form-list {
    width: 100%;
  }
  .contact-detail {
    width: 65% !important;
  }
  .signup-section .cform .form-list {
    width: 55%;
  }
  .box-wrapper.contact-sec {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  /* contact-us by divya */
  .box-wrapper.contact-sec .contact-detail h5 {
    font-size: 13px !important;
  }
  .biolink-area {
    padding: 0;
  }
  .heading-class h1:before {
    width: 60px;
  }
  .heading-class.how-title-sec,
  .last-work {
    order: 2;
  }
  .box-wrapper.contact-sec {
    width: auto;
  }
  .box-wrapper.contact-sec h1,
  .contact-sec .img-section.center-img-section {
    width: 100%;
    font-size: 16px !important;
  }
  .box-wrapper.contact-sec {
    margin-left: 30px;
  }
  .box-wrapper.contact-sec .contact-detail h4 {
    font-size: 15px !important;
  }
  .signup-section .cform .form-list {
    width: 100%;
  }
  .signup-section .cform {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
  }
  .signup-section .signup-contact-details {
    flex-direction: column;
    align-items: flex-start;
  }
  .signup-contact-details .contact-list li {
    display: flex;
  }
  /* how it work by pallavi */
  .testimonial-sec-img img {
    display: block;
    margin: auto;
  }

  .testimonial-heading-sec h1 {
    font-size: 14px !important;
  }
  .how-title-sec h1 {
    font-size: 14px !important;
  }
  .how-title-sec h1:before {
    top: 30px;
  }
  .col-md-6.v-middle p {
    font-size: 12px !important;
  }
  .home-banner .testimonial-heading-sec {
    height: auto;
    margin-top: 35px;
  }
  .v-middle {
    margin-bottom: 18px;
  }
  .howwork-get-link span {
    font-size: 16px !important;
  }
  .v-middle {
    border: none;
  }
  .v-middle p {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }
  .testimonial-sec-img p {
    border: none;
  }
  .howwork-border-sec img {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }
  /*------------------------------------------- privacy policy start---------------------------------------------- */
  .policy-area h4,
  .policy-area li {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 767px) {
  .policy-area p {
    font-size: 11px !important;
  }
  .policy-area.terms-area p {
    font-size: 12px !important;
  }
  .how-title-sec h1:before {
    top: 21px;
  }
  .policy-area h4,
  .policy-area li {
    font-size: 12px !important;
  }
  .signup-section .form-sec1 {
    margin-left: 0;
    color: #fff;
    width: 100%;
  }
  .signup-section h1 {
    font-size: 16px !important;
  }
  .signup-section .new-sec p {
    width: 100%;
    font-size: 14px !important;
  }
  .signup-section .form-sec1 .form-check-sign {
    color: #fff !important;
  }
  .signup-section {
    height: auto;
    background: #3f51b5;
  }
}
/*------------------------------------------- privacy policy end---------------------------------------------- */

/*------------------------------------------- report section start---------------------------------------------- */
.report-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.report-area {
  background-color: #fff;
}
.report-section .report-form {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .report-section {
    flex-direction: column;
  }
  .report-section .report-detail {
    order: 2;
  }
}
/*------------------------------------------- report section end---------------------------------------------- */
/*------------------------------------------- blog section start---------------------------------------------- */
.blog-sec-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.blog-sec-col .blog-box {
  width: 45%;
  height: 45%;
}
.blog-btn {
  padding: 5px 10px !important;
  font-size: 10px !important;
  border: none;
}
.blog-box img {
  height: 100%;
}
.blog-box {
  position: relative;
}
.blog-box .blog-detail {
  position: absolute;
  background: black;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  color: #000;
}
.blog-text a,
.blog-text p,
.blog-text .inline-section span {
  font-size: 10px !important;
}
.blog-text {
  position: absolute;
  padding: 10px 5px;
  bottom: 5px;
  left: 0;
  color: #fff;
  width: 100%;
}
.blog-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(1deg, #141313, #6e5d5d00);
}
.recommend-section .blog-text {
  background: #f5f1f1;
  bottom: 0;
  color: #000;
  padding: 20px;
}
.right-blog-text {
  padding-right: 0;
}
.right-blog-text .blog-box {
  height: 100%;
}
.recommend-section .blog-top-text {
  bottom: 20px;
  padding: 30px 10px;
  width: 90%;
  position: absolute;
  margin: 0 auto;
  background-color: #f5f1f1;
  left: 35px;
}
.recommend-section .right-blog-text .blog-text {
  height: 20%;
}
@media only screen and (max-width: 767px) {
  .blog-box {
    margin: 10px 0px;
  }
  .blog-sec-col .blog-box {
    width: 100%;
    height: auto;
  }
  .blog-page .blog-text {
    padding: 20px 10px;
  }
  .recommend-section .blog-top-text {
    padding: 10px 5px;
    left: 15px;
  }
  .right-blog-text {
    padding-right: 15px !important;
  }
  .recommend-section .text-right {
    padding-right: 20px;
  }
}
/*------------------------------------------- blog section end---------------------------------------------- */
.form-sec1 {
  width: 61%;
  margin-top: 37px;
  margin-left: -40px;
}
.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 12px !important;
}
/*------------------------------------------- term-and-condition-section start---------------------------------------------- */
.term-and-condition-section .policy-area h4 {
  font-size: 14px !important;
}
.term-and-condition-section .policy-area li {
  font-size: 12px !important;
}
@media only screen and (max-width: 767px) {
  .term-and-condition-section .policy-area h4 {
    font-size: 12px !important;
  }
}
/*------------------------------------------- term-and-condition-section end---------------------------------------------- */

/*------------------------------------------- refer-section start---------------------------------------------- */
.refer-section .table tr th:nth-child(1) {
  border-radius: 10px 0 0 10px !important;
}
.refer-section .table tr th:last-child {
  border-radius: 0px 10px 10px 0px !important;
}
.refer-section .inline-label {
  width: 40% !important;
}
.refer-section .long-btn {
  width: 150px !important;
}
.refer-section .referal-input input {
  border-color: blue !important;
  border-radius: 20px 20px !important;
  border: 1px solid blue;
  width: 100%;
}
.refer-section .card-body .input-group {
  align-items: center;
  flex-wrap: nowrap;
}
.refer-section .card-body .input-group .input-group-append {
  margin-left: 10px;
}
.refer-section .btn {
  padding: 8px 25px !important;
}
@media only screen and (max-width: 767px) {
  .refer-section .inline-label,
  .refer-section .form-group {
    width: 100% !important;
  }
}
/*------------------------------------ sell area body section start------------------------------------- */
.sell-area-body .sell-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 5px;
  margin: 5px 0;
}
.sell-area-body .sell-icon img {
  width: 85%;
}
.sell-icon .sell-area-body {
  background-color: #f3f2f2;
}
.sell-area-body .sell-text {
  width: 80%;
}
.sell-area-body .sell-box .next-btn .fa {
  color: #000;
  font-size: 25px;
}
.sell-area-body .sell-text h3,
.sell-area-body .sell-text p {
  margin: 0;
}
.sell-icon .back-section .next-btn {
  display: flex;
}
.sell-ideas .sell-box-detail .sell-input {
  border: 2px solid #c5cbdb;
  font-weight: bold;
}
.sell-ideas .sell-box-detail button {
  width: 100%;
}
.sell-ideas .sell-box-detail .sell-back {
  display: flex;
}
.sell-ideas .sell-box-detail .sell-back i {
  padding-right: 20px;
  font-size: 15px;
  color: blue;
}
.sell-ideas .sell-icon .next-btn {
  display: flex;
  justify-content: space-between;
  font-size: 50px !important;
  color: #000;
  text-decoration: none;
}
.sell-ideas .sell-icon.back-section {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #d7d4d4;
}
.sell-ideas .sell-icon.back-section .sell-back h3 {
  color: #000;
  font-size: 20px !important;
  margin: 0;
}
.sell-ideas .sell-icon.back-section .sell-back {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.sell-ideas .sell-icon.back-section .sell-back img {
  width: 8%;
  padding-right: 10px;
}
.ideas-page {
  background-color: #f9fbfd !important;
}
@media only screen and (max-width: 767px) {
  .sell-area-body .sell-box {
    padding: 15px 5px;
  }
  .sell-area-body .sell-icon {
    width: 12%;
    padding-right: 5px;
  }
  .sell-ideas .sell-icon .next-btn {
    padding: 8px 0px;
    font-size: 32px !important;
  }
  .sell-ideas .sell-icon.back-section .sell-back h3 {
    font-size: 15px !important;
  }
  .sell-ideas .sell-icon.back-section .sell-back img {
    width: 10%;
    padding-right: 5px;
  }
  .sell-ideas .sell-icon.back-section {
    padding: 10px 0;
  }
}
/*------------------------------------------- refer-section end---------------------------------------------- */
@media (prefer-color-scheme: dark) {
  .main-panel .content,
  .bg-main-directory,
  .analytic-box {
    background-color: #3a3837;
    color: #fff;
  }
  .white-box {
    background: #222;
    box-shadow: none;
  }
  .social-links.color.Square .btn-admin {
    background: #080808 !important;
    box-shadow: 0px 0px 5px 5px #323131;
  }

  .card {
    background-color: #222222;
    box-shadow: none;
  }
  .social-list-view li,
  .listurl {
    background: #3a3837;
  }
  .card.main-directory {
    background-color: #222222;
  }
  .fa {
    color: #fff;
  }
  .main-header,
  .main-header .navbar-header,
  .logo-header,
  .sidebar {
    background: #000;
  }
  .main-directory-box,
  .main-directory-box:after {
    background: #3a3837;
    box-shadow: 0px 0px 5px 3px #1e1c1c;
  }
  .form-control {
    color: #fff;
    background-color: #3a3837;
  }
  .main-directory-box p,
  .directory-author h5 {
    color: #d0d0d5 !important;
  }
  #generic_price_table .generic_content,
  .frontend-navigation {
    background-color: #000;
  }
  #generic_price_table .generic_content .generic_feature_list ul li,
  .black-text {
    color: #fff;
  }
  .my-directory .preview-wrapper {
    box-shadow: 0px 0px 10px 10px #3e3d3d;
  }
  .directory-people-slider h3,
  .main-directory-box h3,
  .social-media-form-section .style-section .links,
  .other-detail-list li a {
    color: #fff;
  }
  .all-tab-area a {
    box-shadow: 0px 0px 5px 5px #191a1a;
    color: #fff;
  }
  .modal-content {
    background-color: #000;
  }
  .social-media-form-section .shape-area.color .round-shape,
  .social-media-form-section .shape-area.color .square-shape,
  .social-links.color.Round .btn-admin {
    background-color: #343332;
    box-shadow: 0px 0px 5px 5px #1a1919;
  }
  .modal-body .form-control,
  .css-yk16xz-control {
    background: #1f1f1f;
  }
  .customize-box .minimal-style {
    background: #343332;
  }
  .social-links .btn-admin {
    box-shadow: none;
  }
  footer,
  footer .fa {
    background-color: #000;
  }
}
