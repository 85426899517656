.customize-options {
  margin: 4% 0% 4% 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10%;
  cursor: pointer;
  color: gray;
}
.customize-options.active {
  color: white !important;
  border-radius: 12px;
  background: #1189d1;
  padding: 4px;
}

a.btn-admin.icon-design-admin.customize-btn {
  background: #ffffff;
  box-shadow: 0px 4.14206px 21.7458px #dee7ee;
  border-radius: 8.28411px;
  color: #333;
  text-align: center;
  padding: 6px !important;
  cursor: pointer;
  font-size: 12px !important;
}
